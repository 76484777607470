import { ArrayElement, SchoolModel, SchoolOrgType } from '../api'
import { ServerEnv } from '../servers'
import { devSubPlans } from './development'
import { prodSubPlans } from './production'

interface ConditionalOptions {
  'org_type': SchoolOrgType
}

interface ConditionalPlanSwitch<C extends keyof ConditionalOptions> {
  plan: SubscriptionPlanId,
  condition: [C, '=', ConditionalOptions[C]]
}

export const SubscriptionMeteredEntity = ['pupil', 'teacher', 'class'] as const
export type SubscriptionMeteredEntity = ArrayElement<typeof SubscriptionMeteredEntity>

export interface SubscriptionPlan {
  id: SubscriptionPlanId,
  school: boolean,
  premium?: boolean,
  spelling?: boolean,
  number?: boolean,
  phonics?: boolean,
  phonics_lite_1?: boolean,
  phonics_lite_2?: boolean,
  currency: SubscriptionPlanCurrency,
  basePrice: number,
  priceType: any
  priceTiers?: SubscriptionPlanPriceTier[],
  /**
   * Number of pupils granted by per quantity purchased
   */
  pupils?: number | null,
  /**
   * Number of teachers granted by per quantity purchased
   */
  teachers?: number | null
  /**
   * Number of classes granted by per quantity purchased
   */
  classes?: number
  /**
   * Max quantity purchaseable for tiered pricing
   */
  qtyMax?: number | null
  /**
   * Min quantity purchasable for tiered pricing
   */
  qtyMin?: number | null
  title: string,
  subtitle?: string,
  test?: boolean,
  group?: SubscriptionPlanGroup
  period: SubscriptionPlanPeriod
  accountRegion: SubscriptionPlanAccountRegion
  metered_entity: SubscriptionMeteredEntity | null
  canSwitchTo: SubscriptionPlanId[]
  maybeSwitchTo: ConditionalPlanSwitch<keyof ConditionalOptions>[]
  domestic?: boolean
  // extensionPupilPlan?: string
  // extensionTeacherPlan?: string
  // extensionClassPlan?: string
  // isTrial?: boolean
  // isExtension?: boolean
}

export const SubscriptionPlanCurrency = ['gbp', 'usd', 'eur', 'aud', 'nzd', 'zar'] as const
export type SubscriptionPlanCurrency = ArrayElement<typeof SubscriptionPlanCurrency>

export const SubscriptionPlanGroup = ['edshed', 'litshed'] as const
export type SubscriptionPlanGroup = ArrayElement<typeof SubscriptionPlanGroup>

export const SubscriptionPlanPeriod = ['month', 'year'] as const
export type SubscriptionPlanPeriod = ArrayElement<typeof SubscriptionPlanPeriod>

export const SubscriptionPlanAccountRegion = ['GB', 'US'] as const
export type SubscriptionPlanAccountRegion = ArrayElement<typeof SubscriptionPlanAccountRegion>

export const SubscriptionPlanAccountType = ['school', 'personal'] as const
export type SubscriptionPlanAccountType = ArrayElement<typeof SubscriptionPlanAccountType>

export const SubscriptionPlanCostType = ['free', 'paid'] as const
export type SubscriptionPlanCostType = ArrayElement<typeof SubscriptionPlanCostType>

export type SubscriptionPlanPriceTier = { to: number | null, cost: number }

export const SubscriptionPlanId = [
  'spellingshed-personal-annual',
  'spellingshed-premium-annual',
  'personal',
  'premium',
  'standard',
  'spelling-personal',
  'test-spelling-personal',
  'spelling-premium',
  'number-personal',
  'number-premium',
  'both-personal',
  'both-premium',
  'spelling-personal-usd',
  'spelling-premium-usd',
  'number-personal-usd',
  'number-premium-usd',
  'both-personal-usd',
  'both-premium-usd',
  'litshed-school-2',
  'litshed-school-3',
  'litshed-school-4',
  'litshed-school-5',
  'litshed-school-6',
  'litshed-school-7',
  'litshed-school-8',
  'litshed-school-9',
  'litshed-school-10',
  'litshed-school-11',
  'litshed-school-12',
  'litshed-school-13',
  'litshed-school-14',
  'litshed-school-15',
  'litshed-school-16',
  'litshed-school-17',
  'litshed-school-18',
  'litshed-school-19',
  'litshed-school-20',
  'litshed-school-21',
  'litshed-school-22',
  'litshed-school-23',
  'litshed-school-24',
  'litshed-school-25',
  'litshed-school-26',
  'litshed-school-27',
  'litshed-school-28',
  'litshed-school-29',
  'litshed-school-30',
  'litshed-school-31',
  'litshed-school-32',
  'litshed-school-33',
  'litshed-school-34',
  'litshed-school-35',
  'litshed-school-36',
  'litshed-school-37',
  'litshed-school-38',
  'litshed-school-39',
  'litshed-school-40',
  'litshed-school-2-usd',
  'litshed-school-3-usd',
  'litshed-school-4-usd',
  'litshed-school-5-usd',
  'litshed-school-6-usd',
  'litshed-school-7-usd',
  'litshed-school-8-usd',
  'litshed-school-9-usd',
  'litshed-school-10-usd',
  'litshed-school-11-usd',
  'litshed-school-12-usd',
  'litshed-school-13-usd',
  'litshed-school-14-usd',
  'litshed-school-15-usd',
  'litshed-school-16-usd',
  'litshed-school-17-usd',
  'litshed-school-18-usd',
  'litshed-school-19-usd',
  'litshed-school-20-usd',
  'litshed-school-21-usd',
  'litshed-school-22-usd',
  'litshed-school-23-usd',
  'litshed-school-24-usd',
  'litshed-school-25-usd',
  'litshed-school-26-usd',
  'litshed-school-27-usd',
  'litshed-school-28-usd',
  'litshed-school-29-usd',
  'litshed-school-30-usd',
  'litshed-school-31-usd',
  'litshed-school-32-usd',
  'litshed-school-33-usd',
  'litshed-school-34-usd',
  'litshed-school-35-usd',
  'litshed-school-36-usd',
  'litshed-school-37-usd',
  'litshed-school-38-usd',
  'litshed-school-39-usd',
  'litshed-school-40-usd',
  'litshed-personal',
  'litshed-personal-usd',
  'price_1Gu4DzEwnA0RkkU7Q78whUIE',
  'price_1Gu4ICEwnA0RkkU7WnYDEbrQ',
  'litshed-personal-eur',
  'litshed-personal-aud',
  'litshed-personal-nzd',
  'litshed-personal-zar',
  'litshed-metered-school-gbp',
  'litshed-metered-school-usd',
  'litshed-metered-school-zar',
  'price_1Gu4FLEwnA0RkkU7KnWuPopi',
  'price_1Gu4ICEwnA0RkkU7PYW0jvUh',
  'litshed-metered-school-eur',
  'litshed-metered-school-aud',
  'litshed-metered-school-nzd',
  'edshed-metered-spelling-home-yearly-gbp',
  'test-edshed-metered-spelling-home-yearly-gbp',
  'edshed-metered-spelling-home-monthly-gbp',
  'edshed-metered-number-home-yearly-gbp',
  'edshed-metered-number-home-monthly-gbp',
  'test-edshed-metered-number-home-monthly-gbp',
  'edshed-metered-combined-home-yearly-gbp',
  'test-edshed-metered-combined-home-yearly-gbp',
  'edshed-metered-combined-home-monthly-gbp',
  'edshed-metered-spelling-home-yearly-usd',
  'edshed-metered-number-home-yearly-usd',
  'edshed-metered-combined-home-yearly-usd',
  'price_1Gu4agEwnA0RkkU71DwvmCjz',
  'price_1Gu4agEwnA0RkkU717cgpeI8',
  'price_1Gu4agEwnA0RkkU7dBzKhlVh',
  'edshed-metered-spelling-home-yearly-eur',
  'edshed-metered-number-home-yearly-eur',
  'edshed-metered-combined-home-yearly-eur',
  'edshed-metered-spelling-home-yearly-aud',
  'edshed-metered-number-home-yearly-aud',
  'edshed-metered-combined-home-yearly-aud',
  'edshed-metered-spelling-home-yearly-nzd',
  'edshed-metered-number-home-yearly-nzd',
  'edshed-metered-combined-home-yearly-nzd',
  'edshed-metered-spelling-school-yearly-gbp',
  'edshed-metered-number-school-yearly-gbp',
  'edshed-metered-combined-school-yearly-gbp',
  'edshed-metered-spelling-school-yearly-usd',
  'edshed-metered-number-school-yearly-usd',
  'edshed-metered-combined-school-yearly-usd',
  'price_1Gu4agEwnA0RkkU7GPfLLid4',
  'price_1Gu4agEwnA0RkkU7Cujvtk7w',
  'price_1Gu4agEwnA0RkkU7wVvr6g8W',
  'edshed-metered-spelling-school-yearly-eur',
  'edshed-metered-number-school-yearly-eur',
  'edshed-metered-combined-school-yearly-eur',
  'edshed-metered-spelling-school-yearly-aud',
  'edshed-metered-number-school-yearly-aud',
  'edshed-metered-combined-school-yearly-aud',
  'edshed-metered-spelling-school-yearly-nzd',
  'edshed-metered-number-school-yearly-nzd',
  'edshed-metered-combined-school-yearly-nzd',
  'price_1KQV0CEwnA0RkkU7YXkuPJYj',
  'price_1KQUzZEwnA0RkkU7RfdBlZyA',
  'price_1KQUzHEwnA0RkkU7yJCghvz9',
  'price_1Jp7OFEwnA0RkkU76DDwnsTl',
  'price_1Jp7OZEwnA0RkkU7paqayfjG',
  'price_1Jp7OxEwnA0RkkU78bExnGfJ',
  'edshed-metered-phonics-yearly-gbp',
  'edshed-metered-phonics-digital-yearly-gbp',
  'edshed-metered-phonics-digital-yearly-eur',
  'edshed-metered-phonics-digital-yearly-aud',
  'edshed-metered-phonics-digital-yearly-nzd',
  'edshed-metered-spelling-school-yearly-gbp-mar22',
  'edshed-metered-number-school-yearly-gbp-mar22',
  'edshed-metered-combined-school-yearly-gbp-mar22',
  'litshed-metered-school-gbp-mar22',
  'litshed-personal-mar22',
  'price_1L3bfhEwnA0RkkU7oQZnBwmT',
  'price_1Gu4aEEwnA0RkkU7twUAwyEG',
  'price_1Gu4YqEwnA0RkkU7sw73UzBx',
  'price_1Gu4XAEwnA0RkkU7z4X2zKOv',
  'price_1L3dqNEwnA0RkkU70Qbwf9NX',
  'edshed-metered-spelling-school-yearly-zar',
  'edshed-metered-number-school-yearly-zar',
  'edshed-metered-combined-school-yearly-zar',
  'edshed-metered-phonics-yearly-zar',
  'edshed-metered-number-home-yearly-zar',
  'edshed-metered-spelling-home-yearly-zar',
  'edshed-metered-combined-home-yearly-zar',
  'edshed-metered-spelling-school-yearly-gbp-jan23',
  'edshed-metered-number-school-yearly-gbp-jan23',
  'edshed-metered-combined-school-yearly-gbp-jan23',
  'litshed-personal-jan23',
  'litshed-metered-school-gbp-jan23',
  'edshed-metered-spelling-school-yearly-eur-jan23',
  'edshed-metered-number-school-yearly-eur-jan23',
  'edshed-metered-combined-school-yearly-eur-jan23',
  'litshed-personal-eur-jan23',
  'litshed-metered-school-eur-jan23',
  'edshed-metered-spelling-school-yearly-aud-jan23',
  'edshed-metered-number-school-yearly-aud-jan23',
  'edshed-metered-combined-school-yearly-aud-jan23',
  'litshed-personal-aud-jan23',
  'litshed-metered-school-aud-jan23',
  'edshed-metered-spelling-school-yearly-nzd-jan23',
  'edshed-metered-number-school-yearly-nzd-jan23',
  'edshed-metered-combined-school-yearly-nzd-jan23',
  'litshed-personal-nzd-jan23',
  'litshed-metered-school-nzd-jan23',
  'edshed-metered-spelling-school-yearly-zar-jan23',
  'edshed-metered-number-school-yearly-zar-jan23',
  'edshed-metered-combined-school-yearly-zar-jan23',
  'litshed-personal-zar-jan23',
  'litshed-metered-school-zar-jan23',
  'price_1MI9IWEwnA0RkkU7eZgLJHGp', // school - spelling - jan23
  'price_1MI9OvEwnA0RkkU7HNRslwAv', // school - number - jan23
  'price_1MI9TlEwnA0RkkU7OsAviLQM', // school - combined - jan23
  'price_1MI95rEwnA0RkkU7HtRwrYD7', // litshed - personal - jan23
  'price_1MI9C0EwnA0RkkU7IH6jiGlR', // litshed - school - jan23
  'edshed-metered-spelling-home-yearly-gbp-jan23',
  'edshed-metered-number-home-yearly-gbp-jan23',
  'edshed-metered-combined-home-yearly-gbp-jan23',
  'edshed-metered-spelling-home-monthly-gbp-jan23',
  'edshed-metered-number-home-monthly-gbp-jan23',
  'edshed-metered-combined-home-monthly-gbp-jan23',
  'edshed-metered-spelling-home-yearly-eur-jan23',
  'edshed-metered-number-home-yearly-eur-jan23',
  'edshed-metered-combined-home-yearly-eur-jan23',
  'edshed-metered-spelling-home-yearly-aud-jan23',
  'edshed-metered-number-home-yearly-aud-jan23',
  'edshed-metered-combined-home-yearly-aud-jan23',
  'edshed-metered-spelling-home-yearly-nzd-jan23',
  'edshed-metered-number-home-yearly-nzd-jan23',
  'edshed-metered-combined-home-yearly-nzd-jan23',
  'edshed-metered-spelling-home-yearly-zar-jan23',
  'edshed-metered-number-home-yearly-zar-jan23',
  'edshed-metered-combined-home-yearly-zar-jan23',
  'price_1MI9a6EwnA0RkkU7dAz9lfDP', // home - spelling - jan23
  'price_1MI9cUEwnA0RkkU7WOlX2GBe', // home - number - jan23
  'price_1MI9gMEwnA0RkkU7ZSkSC50Y', // home - combined - jan23
  'edshed-metered-spelling-school-yearly-gbp-intl-feb23',
  'edshed-metered-number-school-yearly-gbp-intl-jan23',
  'edshed-metered-combined-school-yearly-gbp-intl-jan23',
  'edshed-metered-spelling-home-yearly-gbp-intl-jan23',
  'edshed-metered-number-home-yearly-gbp-intl-jan23',
  'edshed-metered-combined-home-yearly-gbp-intl-jan23',
  'edshed-metered-spelling-school-yearly-eur-dom-jan23',
  'edshed-metered-number-school-yearly-eur-dom-jan23',
  'edshed-metered-combined-school-yearly-eur-dom-jan23',
  'edshed-metered-spelling-home-yearly-eur-dom-jan23',
  'edshed-metered-number-home-yearly-eur-dom-jan23',
  'edshed-metered-combined-home-yearly-eur-dom-jan23',
  'litshed-personal-gbp-intl-jan23',
  'litshed-metered-school-gbp-intl-jan23',
  'litshed-metered-school-gbp-intl-feb23',
  'litshed-personal-eur-dom-jan23',
  'litshed-metered-school-eur-dom-jan23'
] as const
export type SubscriptionPlanId = ArrayElement<typeof SubscriptionPlanId>

export function getTierForQuantity (quantity: number, tiers: SubscriptionPlanPriceTier[]) {
  return tiers.reduce((prev, val) => {
    if (prev === undefined) {
      if (val.to === null || val.to >= quantity) {
        return val
      } else {
        return prev
      }
    }

    if (prev.to === null) {
      if (val.to !== null && val.to >= quantity) {
        return val
      }
    } else if (val.to !== null && val.to >= quantity && val.to < prev.to) {
      return val
    }

    return prev
  }, undefined as SubscriptionPlanPriceTier | undefined)
}

export function getTieredPrice (num: number, tiers: { to: null | number, cost: number }[]) {
  let runningTotal = 0
  // we want from null then descending
  let tt: { to: null | number, cost: number }[] = []
  tt = tt.concat(tiers)
  tt = tt.sort((a, b) => {
    if (!a.to) { return 1 }
    if (!b.to) { return -1 }
    if (a.to < b.to) { return -1 }
    if (a.to > b.to) { return 1 }
    return 0
  })
  // console.log(tiers)
  for (let i = num; i > 0; i--) {
    for (const t of tt) {
      if (i <= t.to!) {
        runningTotal += t.cost
        // console.log('ADDING: ' + t.cost)
        break
      }
      if (!t.to) {
        // console.log('ADDING: ' + t.cost)
        runningTotal += t.cost
      }
    }
  }
  return runningTotal
}

export function canPackageUpgradeToPackage (oldPackage: SubscriptionPlan, newPackage: SubscriptionPlan, school: SchoolModel) {
  if (oldPackage.canSwitchTo.includes(newPackage.id)) {
    return true
  }

  return oldPackage.maybeSwitchTo.find((p) => {
    if (p.plan !== newPackage.id) {
      return false
    }

    switch (p.condition[0]) {
      case 'org_type':
        return school.org_type === p.condition[2]
      default:
        return false
    }
  }) !== undefined
}

export function getAllPlans (env: ServerEnv) {
  if (env === 'production') {
    return prodSubPlans
  } else {
    return devSubPlans
  }
}
