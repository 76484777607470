import { SubscriptionPlan, SubscriptionPlanId } from '..'

type SubDef = { [K in SubscriptionPlanId]: (SubscriptionPlan & { id: K, test?: false }) }

// to match the active function to the id of the plan use regex find: "id:\s('[\w-]*')([.\r\s,\w:']*)active:(.*)," and replace with "id: $1$2active: () => isActive($1)," (remove quotes)

const subObj: Partial<SubDef> = {
  'spellingshed-personal-annual': {
    id: 'spellingshed-personal-annual',
    accountRegion: 'GB',
    period: 'year',

    group: 'edshed',
    school: false,
    premium: true,
    spelling: true,
    number: false,
    phonics: false,
    currency: 'gbp',
    basePrice: 20,
    pupils: 36,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Spelling Shed Personal',
    canSwitchTo: ['edshed-metered-spelling-school-yearly-gbp', 'edshed-metered-combined-school-yearly-gbp'],
    maybeSwitchTo: []
  },
  'spellingshed-premium-annual': {
    id: 'spellingshed-premium-annual',
    accountRegion: 'GB',
    period: 'year',

    group: 'edshed',
    school: false,
    premium: true,
    spelling: true,
    number: false,
    phonics: false,
    currency: 'gbp',
    basePrice: 100,
    pupils: 36,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Spelling Shed Personal',
    canSwitchTo: ['edshed-metered-spelling-school-yearly-gbp', 'edshed-metered-combined-school-yearly-gbp'],
    maybeSwitchTo: []
  },
  personal:
  {
    id: 'personal',
    accountRegion: 'GB',
    period: 'year',

    group: 'edshed',
    school: false,
    premium: true,
    spelling: true,
    number: false,
    phonics: false,
    currency: 'gbp',
    basePrice: 20,
    priceType: 'fixed',
    metered_entity: null,
    pupils: 36,
    title: 'Spelling Shed Personal',
    canSwitchTo: ['edshed-metered-spelling-school-yearly-gbp', 'edshed-metered-combined-school-yearly-gbp'],
    maybeSwitchTo: []
  },
  premium:
  {
    id: 'premium',
    accountRegion: 'GB',
    period: 'year',

    group: 'edshed',
    school: true,
    premium: true,
    spelling: true,
    number: false,
    phonics: false,
    currency: 'gbp',
    basePrice: 100,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Spelling Shed Premium',
    canSwitchTo: ['edshed-metered-spelling-school-yearly-gbp', 'edshed-metered-combined-school-yearly-gbp'],
    maybeSwitchTo: []
  },
  standard:
  {
    id: 'standard',
    accountRegion: 'GB',
    period: 'year',

    group: 'edshed',
    school: true,
    premium: false,
    spelling: true,
    number: false,
    phonics: false,
    currency: 'gbp',
    basePrice: 60,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Spelling Shed Standard',
    canSwitchTo: ['edshed-metered-spelling-school-yearly-gbp', 'edshed-metered-combined-school-yearly-gbp'],
    maybeSwitchTo: []
  },
  'spelling-personal':
  {
    id: 'spelling-personal',
    accountRegion: 'GB',
    period: 'year',

    group: 'edshed',
    school: false,
    premium: true,
    spelling: true,
    number: false,
    phonics: false,
    currency: 'gbp',
    basePrice: 30,
    priceType: 'fixed',
    metered_entity: null,
    pupils: 36,
    title: 'Spelling Shed Personal',
    canSwitchTo: ['edshed-metered-spelling-school-yearly-gbp', 'edshed-metered-combined-school-yearly-gbp'],
    maybeSwitchTo: []
  },
  'spelling-premium': {
    id: 'spelling-premium',
    accountRegion: 'GB',
    period: 'year',

    group: 'edshed',
    school: true,
    premium: true,
    spelling: true,
    number: false,
    phonics: false,
    currency: 'gbp',
    basePrice: 150,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Spelling Shed Premium',
    canSwitchTo: ['edshed-metered-spelling-school-yearly-gbp', 'edshed-metered-combined-school-yearly-gbp'],
    maybeSwitchTo: []
  },
  'number-personal': {
    id: 'number-personal',
    accountRegion: 'GB',
    period: 'year',

    group: 'edshed',
    school: false,
    premium: true,
    spelling: false,
    number: true,
    phonics: false,
    currency: 'gbp',
    basePrice: 30,
    priceType: 'fixed',
    metered_entity: null,
    pupils: 36,
    title: 'MathShed Personal',
    canSwitchTo: ['edshed-metered-number-school-yearly-gbp', 'edshed-metered-combined-school-yearly-gbp'],
    maybeSwitchTo: []
  },
  'number-premium': {
    id: 'number-premium',
    accountRegion: 'GB',
    period: 'year',

    group: 'edshed',
    school: true,
    premium: true,
    spelling: false,
    number: true,
    phonics: false,
    currency: 'gbp',
    basePrice: 150,
    priceType: 'fixed',
    metered_entity: null,
    title: 'MathShed Premium',
    canSwitchTo: ['edshed-metered-number-school-yearly-gbp', 'edshed-metered-combined-school-yearly-gbp'],
    maybeSwitchTo: []
  },
  'both-personal': {
    id: 'both-personal',
    accountRegion: 'GB',
    period: 'year',

    group: 'edshed',
    school: false,
    premium: true,
    spelling: true,
    number: true,
    phonics: false,
    currency: 'gbp',
    basePrice: 50,
    priceType: 'fixed',
    metered_entity: null,
    pupils: 36,
    title: 'Spelling Shed + MathShed Personal',
    canSwitchTo: ['edshed-metered-combined-school-yearly-gbp'],
    maybeSwitchTo: []
  },
  'both-premium': {
    id: 'both-premium',
    accountRegion: 'GB',
    period: 'year',

    group: 'edshed',
    school: true,
    premium: true,
    spelling: true,
    number: true,
    phonics: false,
    currency: 'gbp',
    basePrice: 250,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Spelling Shed + MathShed Premium',
    canSwitchTo: ['edshed-metered-combined-school-yearly-gbp'],
    maybeSwitchTo: []
  },
  'spelling-personal-usd': {
    id: 'spelling-personal-usd',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    school: false,
    premium: true,
    spelling: true,
    number: false,
    phonics: false,
    currency: 'usd',
    basePrice: 42,
    priceType: 'fixed',
    metered_entity: null,
    pupils: 36,
    title: 'Spelling Shed Personal',
    canSwitchTo: ['edshed-metered-spelling-school-yearly-usd', 'edshed-metered-combined-school-yearly-usd'],
    maybeSwitchTo: []
  },
  'spelling-premium-usd': {
    id: 'spelling-premium-usd',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    school: true,
    premium: true,
    spelling: true,
    number: false,
    phonics: false,
    currency: 'usd',
    basePrice: 210,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Spelling Shed Premium',
    canSwitchTo: ['edshed-metered-spelling-school-yearly-usd', 'edshed-metered-combined-school-yearly-usd'],
    maybeSwitchTo: []
  },
  'number-personal-usd': {
    id: 'number-personal-usd',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    school: false,
    premium: true,
    spelling: false,
    number: true,
    phonics: false,
    currency: 'usd',
    basePrice: 42,
    priceType: 'fixed',
    metered_entity: null,
    pupils: 36,
    title: 'MathShed Personal',
    canSwitchTo: ['edshed-metered-number-school-yearly-usd', 'edshed-metered-combined-school-yearly-usd'],
    maybeSwitchTo: []
  },
  'number-premium-usd': {
    id: 'number-premium-usd',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    school: true,
    premium: true,
    spelling: false,
    number: true,
    phonics: false,
    currency: 'usd',
    basePrice: 210,
    priceType: 'fixed',
    metered_entity: null,
    title: 'MathShed Premium',
    canSwitchTo: ['edshed-metered-number-school-yearly-usd', 'edshed-metered-combined-school-yearly-usd'],
    maybeSwitchTo: []
  },
  'both-personal-usd': {
    id: 'both-personal-usd',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    school: false,
    premium: true,
    spelling: true,
    number: true,
    phonics: false,
    currency: 'usd',
    basePrice: 70,
    priceType: 'fixed',
    metered_entity: null,
    pupils: 36,
    title: 'Spelling Shed + MathShed Personal',
    canSwitchTo: ['edshed-metered-combined-school-yearly-usd'],
    maybeSwitchTo: []
  },
  'both-premium-usd': {
    id: 'both-premium-usd',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    school: true,
    premium: true,
    spelling: true,
    number: true,
    phonics: false,
    currency: 'usd',
    basePrice: 350,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Spelling Shed + MathShed Premium',
    canSwitchTo: ['edshed-metered-combined-school-yearly-usd'],
    maybeSwitchTo: []
  },
  'litshed-school-2': {
    id: 'litshed-school-2',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'gbp',
    classes: 2,
    teachers: 8,
    basePrice: 39.00,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (2 Classes)',
    canSwitchTo: ['litshed-metered-school-gbp'],
    maybeSwitchTo: []
  },
  'litshed-school-3': {
    id: 'litshed-school-3',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'gbp',
    classes: 3,
    teachers: 12,
    basePrice: 58.50,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (3 Classes)',
    canSwitchTo: ['litshed-metered-school-gbp'],
    maybeSwitchTo: []
  },
  'litshed-school-4': {
    id: 'litshed-school-4',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'gbp',
    classes: 4,
    teachers: 16,
    basePrice: 78.00,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (4 Classes)',
    canSwitchTo: ['litshed-metered-school-gbp'],
    maybeSwitchTo: []
  },
  'litshed-school-5': {
    id: 'litshed-school-5',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'gbp',
    classes: 5,
    teachers: 20,
    basePrice: 97.50,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (5 Classes)',
    canSwitchTo: ['litshed-metered-school-gbp'],
    maybeSwitchTo: []
  },
  'litshed-school-6': {
    id: 'litshed-school-6',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'gbp',
    classes: 6,
    teachers: 24,
    basePrice: 117.00,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (6 Classes)',
    canSwitchTo: ['litshed-metered-school-gbp'],
    maybeSwitchTo: []
  },
  'litshed-school-7': {
    id: 'litshed-school-7',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'gbp',
    classes: 7,
    teachers: 28,
    basePrice: 136.50,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (7 Classes)',
    canSwitchTo: ['litshed-metered-school-gbp'],
    maybeSwitchTo: []
  },
  'litshed-school-8': {
    id: 'litshed-school-8',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'gbp',
    classes: 8,
    teachers: 32,
    basePrice: 156.00,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (8 Classes)',
    canSwitchTo: ['litshed-metered-school-gbp'],
    maybeSwitchTo: []
  },
  'litshed-school-9': {
    id: 'litshed-school-9',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'gbp',
    classes: 9,
    teachers: 36,
    basePrice: 175.50,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (9 Classes)',
    canSwitchTo: ['litshed-metered-school-gbp'],
    maybeSwitchTo: []
  },
  'litshed-school-10': {
    id: 'litshed-school-10',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'gbp',
    classes: 10,
    teachers: 40,
    basePrice: 195.00,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (10 Classes)',
    canSwitchTo: ['litshed-metered-school-gbp'],
    maybeSwitchTo: []
  },
  'litshed-school-11': {
    id: 'litshed-school-11',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'gbp',
    classes: 11,
    teachers: 44,
    basePrice: 214.50,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (11 Classes)',
    canSwitchTo: ['litshed-metered-school-gbp'],
    maybeSwitchTo: []
  },
  'litshed-school-12': {
    id: 'litshed-school-12',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'gbp',
    classes: 12,
    teachers: 48,
    basePrice: 234.00,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (12 Classes)',
    canSwitchTo: ['litshed-metered-school-gbp'],
    maybeSwitchTo: []
  },
  'litshed-school-13': {
    id: 'litshed-school-13',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'gbp',
    classes: 13,
    teachers: 52,
    basePrice: 253.5,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (13 Classes)',
    canSwitchTo: ['litshed-metered-school-gbp'],
    maybeSwitchTo: []
  },
  'litshed-school-14': {
    id: 'litshed-school-14',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'gbp',
    classes: 14,
    teachers: 56,
    basePrice: 273,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (14 Classes)',
    canSwitchTo: ['litshed-metered-school-gbp'],
    maybeSwitchTo: []
  },
  'litshed-school-15': {
    id: 'litshed-school-15',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'gbp',
    classes: 15,
    teachers: 60,
    basePrice: 292.5,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (15 Classes)',
    canSwitchTo: ['litshed-metered-school-gbp'],
    maybeSwitchTo: []
  },
  'litshed-school-16': {
    id: 'litshed-school-16',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'gbp',
    classes: 16,
    teachers: 64,
    basePrice: 312,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (16 Classes)',
    canSwitchTo: ['litshed-metered-school-gbp'],
    maybeSwitchTo: []
  },
  'litshed-school-17': {
    id: 'litshed-school-17',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'gbp',
    classes: 17,
    teachers: 68,
    basePrice: 331.5,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (17 Classes)',
    canSwitchTo: ['litshed-metered-school-gbp'],
    maybeSwitchTo: []
  },
  'litshed-school-18': {
    id: 'litshed-school-18',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'gbp',
    classes: 18,
    teachers: 72,
    basePrice: 351,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (18 Classes)',
    canSwitchTo: ['litshed-metered-school-gbp'],
    maybeSwitchTo: []
  },
  'litshed-school-19': {
    id: 'litshed-school-19',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'gbp',
    classes: 19,
    teachers: 76,
    basePrice: 370.5,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (19 Classes)',
    canSwitchTo: ['litshed-metered-school-gbp'],
    maybeSwitchTo: []
  },
  'litshed-school-20': {
    id: 'litshed-school-20',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'gbp',
    classes: 20,
    teachers: 80,
    basePrice: 390,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (20 Classes)',
    canSwitchTo: ['litshed-metered-school-gbp'],
    maybeSwitchTo: []
  },
  'litshed-school-21': {
    id: 'litshed-school-21',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'gbp',
    classes: 21,
    teachers: 84,
    basePrice: 409.5,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (21 Classes)',
    canSwitchTo: ['litshed-metered-school-gbp'],
    maybeSwitchTo: []
  },
  'litshed-school-22': {
    id: 'litshed-school-22',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'gbp',
    classes: 22,
    teachers: 88,
    basePrice: 429.00,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (22 Classes)',
    canSwitchTo: ['litshed-metered-school-gbp'],
    maybeSwitchTo: []
  },
  'litshed-school-23': {
    id: 'litshed-school-23',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'gbp',
    classes: 23,
    teachers: 92,
    basePrice: 448.50,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (23 Classes)',
    canSwitchTo: ['litshed-metered-school-gbp'],
    maybeSwitchTo: []
  },
  'litshed-school-24': {
    id: 'litshed-school-24',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'gbp',
    classes: 24,
    teachers: 96,
    basePrice: 468.00,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (24 Classes)',
    canSwitchTo: ['litshed-metered-school-gbp'],
    maybeSwitchTo: []
  },
  'litshed-school-25': {
    id: 'litshed-school-25',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'gbp',
    classes: 25,
    teachers: 100,
    basePrice: 487.50,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (25 Classes)',
    canSwitchTo: ['litshed-metered-school-gbp'],
    maybeSwitchTo: []
  },
  'litshed-school-26': {
    id: 'litshed-school-26',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'gbp',
    classes: 26,
    teachers: 104,
    basePrice: 507.00,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (26 Classes)',
    canSwitchTo: ['litshed-metered-school-gbp'],
    maybeSwitchTo: []
  },
  'litshed-school-27': {
    id: 'litshed-school-27',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'gbp',
    classes: 27,
    teachers: 108,
    basePrice: 526.50,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (27 Classes)',
    canSwitchTo: ['litshed-metered-school-gbp'],
    maybeSwitchTo: []
  },
  'litshed-school-28': {
    id: 'litshed-school-28',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'gbp',
    classes: 28,
    teachers: 112,
    basePrice: 546.00,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (28 Classes)',
    canSwitchTo: ['litshed-metered-school-gbp'],
    maybeSwitchTo: []
  },
  'litshed-school-29': {
    id: 'litshed-school-29',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'gbp',
    classes: 29,
    teachers: 116,
    basePrice: 565.50,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (29 Classes)',
    canSwitchTo: ['litshed-metered-school-gbp'],
    maybeSwitchTo: []
  },
  'litshed-school-30': {
    id: 'litshed-school-30',
    accountRegion: 'GB',
    group: 'litshed',
    school: true,
    period: 'year',

    currency: 'gbp',
    classes: 30,
    teachers: 120,
    basePrice: 585,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (30 Classes)',
    canSwitchTo: ['litshed-metered-school-gbp'],
    maybeSwitchTo: []
  },
  'litshed-school-31': {
    id: 'litshed-school-31',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'gbp',
    classes: 31,
    teachers: 124,
    basePrice: 604.5,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (31 Classes)',
    canSwitchTo: ['litshed-metered-school-gbp'],
    maybeSwitchTo: []
  },
  'litshed-school-32': {
    id: 'litshed-school-32',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'gbp',
    classes: 32,
    teachers: 128,
    basePrice: 624,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (32 Classes)',
    canSwitchTo: ['litshed-metered-school-gbp'],
    maybeSwitchTo: []
  },
  'litshed-school-33': {
    id: 'litshed-school-33',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'gbp',
    classes: 33,
    teachers: 132,
    basePrice: 643.5,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (33 Classes)',
    canSwitchTo: ['litshed-metered-school-gbp'],
    maybeSwitchTo: []
  },
  'litshed-school-34': {
    id: 'litshed-school-34',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'gbp',
    classes: 34,
    teachers: 136,
    basePrice: 663,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (34 Classes)',
    canSwitchTo: ['litshed-metered-school-gbp'],
    maybeSwitchTo: []
  },
  'litshed-school-35': {
    id: 'litshed-school-35',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'gbp',
    classes: 35,
    teachers: 140,
    basePrice: 682.5,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (35 Classes)',
    canSwitchTo: ['litshed-metered-school-gbp'],
    maybeSwitchTo: []
  },
  'litshed-school-36': {
    id: 'litshed-school-36',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'gbp',
    classes: 36,
    teachers: 144,
    basePrice: 702,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (36 Classes)',
    canSwitchTo: ['litshed-metered-school-gbp'],
    maybeSwitchTo: []
  },
  'litshed-school-37': {
    id: 'litshed-school-37',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'gbp',
    classes: 37,
    teachers: 148,
    basePrice: 721.5,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (37 Classes)',
    canSwitchTo: ['litshed-metered-school-gbp'],
    maybeSwitchTo: []
  },
  'litshed-school-38': {
    id: 'litshed-school-38',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'gbp',
    classes: 38,
    teachers: 152,
    basePrice: 741,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (38 Classes)',
    canSwitchTo: ['litshed-metered-school-gbp'],
    maybeSwitchTo: []
  },
  'litshed-school-39': {
    id: 'litshed-school-39',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'gbp',
    classes: 39,
    teachers: 156,
    basePrice: 760.5,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (39 Classes)',
    canSwitchTo: ['litshed-metered-school-gbp'],
    maybeSwitchTo: []
  },
  'litshed-school-40': {
    id: 'litshed-school-40',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'gbp',
    classes: 40,
    teachers: 160,
    basePrice: 780,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (40 Classes)',
    canSwitchTo: ['litshed-metered-school-gbp'],
    maybeSwitchTo: []
  },
  'litshed-school-2-usd': {
    id: 'litshed-school-2-usd',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'usd',
    classes: 2,
    teachers: 8,
    basePrice: 59.00,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (2 Classes)',
    canSwitchTo: ['price_1Gu4FLEwnA0RkkU7KnWuPopi'],
    maybeSwitchTo: []
  },
  'litshed-school-3-usd': {
    id: 'litshed-school-3-usd',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'usd',
    classes: 3,
    teachers: 12,
    basePrice: 88.50,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (3 Classes)',
    canSwitchTo: ['price_1Gu4FLEwnA0RkkU7KnWuPopi'],
    maybeSwitchTo: []
  },
  'litshed-school-4-usd': {
    id: 'litshed-school-4-usd',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'usd',
    classes: 4,
    teachers: 16,
    basePrice: 118.00,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (4 Classes)',
    canSwitchTo: ['price_1Gu4FLEwnA0RkkU7KnWuPopi'],
    maybeSwitchTo: []
  },
  'litshed-school-5-usd': {
    id: 'litshed-school-5-usd',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'usd',
    classes: 5,
    teachers: 20,
    basePrice: 147.50,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (5 Classes)',
    canSwitchTo: ['price_1Gu4FLEwnA0RkkU7KnWuPopi'],
    maybeSwitchTo: []
  },
  'litshed-school-6-usd': {
    id: 'litshed-school-6-usd',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'usd',
    classes: 6,
    teachers: 24,
    basePrice: 177.00,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (6 Classes)',
    canSwitchTo: ['price_1Gu4FLEwnA0RkkU7KnWuPopi'],
    maybeSwitchTo: []
  },
  'litshed-school-7-usd': {
    id: 'litshed-school-7-usd',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'usd',
    classes: 7,
    teachers: 28,
    basePrice: 206.50,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (7 Classes)',
    canSwitchTo: ['price_1Gu4FLEwnA0RkkU7KnWuPopi'],
    maybeSwitchTo: []
  },
  'litshed-school-8-usd': {
    id: 'litshed-school-8-usd',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'usd',
    classes: 8,
    teachers: 32,
    basePrice: 236.00,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (8 Classes)',
    canSwitchTo: ['price_1Gu4FLEwnA0RkkU7KnWuPopi'],
    maybeSwitchTo: []
  },
  'litshed-school-9-usd': {
    id: 'litshed-school-9-usd',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'usd',
    classes: 9,
    teachers: 36,
    basePrice: 265.50,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (9 Classes)',
    canSwitchTo: ['price_1Gu4FLEwnA0RkkU7KnWuPopi'],
    maybeSwitchTo: []
  },
  'litshed-school-10-usd': {
    id: 'litshed-school-10-usd',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'usd',
    classes: 10,
    teachers: 40,
    basePrice: 295.00,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (10 Classes)',
    canSwitchTo: ['price_1Gu4FLEwnA0RkkU7KnWuPopi'],
    maybeSwitchTo: []
  },
  'litshed-school-11-usd': {
    id: 'litshed-school-11-usd',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'usd',
    classes: 11,
    teachers: 44,
    basePrice: 324.50,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (11 Classes)',
    canSwitchTo: ['price_1Gu4FLEwnA0RkkU7KnWuPopi'],
    maybeSwitchTo: []
  },
  'litshed-school-12-usd': {
    id: 'litshed-school-12-usd',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'usd',
    classes: 12,
    teachers: 48,
    basePrice: 354.00,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (12 Classes)',
    canSwitchTo: ['price_1Gu4FLEwnA0RkkU7KnWuPopi'],
    maybeSwitchTo: []
  },
  'litshed-school-13-usd': {
    id: 'litshed-school-13-usd',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'usd',
    classes: 13,
    teachers: 52,
    basePrice: 383.5,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (13 Classes)',
    canSwitchTo: ['price_1Gu4FLEwnA0RkkU7KnWuPopi'],
    maybeSwitchTo: []
  },
  'litshed-school-14-usd': {
    id: 'litshed-school-14-usd',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'usd',
    classes: 14,
    teachers: 56,
    basePrice: 413,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (14 Classes)',
    canSwitchTo: ['price_1Gu4FLEwnA0RkkU7KnWuPopi'],
    maybeSwitchTo: []
  },
  'litshed-school-15-usd': {
    id: 'litshed-school-15-usd',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'usd',
    classes: 15,
    teachers: 60,
    basePrice: 442.5,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (15 Classes)',
    canSwitchTo: ['price_1Gu4FLEwnA0RkkU7KnWuPopi'],
    maybeSwitchTo: []
  },
  'litshed-school-16-usd': {
    id: 'litshed-school-16-usd',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'usd',
    classes: 16,
    teachers: 64,
    basePrice: 472,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (16 Classes)',
    canSwitchTo: ['price_1Gu4FLEwnA0RkkU7KnWuPopi'],
    maybeSwitchTo: []
  },
  'litshed-school-17-usd': {
    id: 'litshed-school-17-usd',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'usd',
    classes: 17,
    teachers: 68,
    basePrice: 501.5,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (17 Classes)',
    canSwitchTo: ['price_1Gu4FLEwnA0RkkU7KnWuPopi'],
    maybeSwitchTo: []
  },
  'litshed-school-18-usd': {
    id: 'litshed-school-18-usd',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'usd',
    classes: 18,
    teachers: 72,
    basePrice: 531,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (18 Classes)',
    canSwitchTo: ['price_1Gu4FLEwnA0RkkU7KnWuPopi'],
    maybeSwitchTo: []
  },
  'litshed-school-19-usd': {
    id: 'litshed-school-19-usd',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'usd',
    classes: 19,
    teachers: 76,
    basePrice: 560.5,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (19 Classes)',
    canSwitchTo: ['price_1Gu4FLEwnA0RkkU7KnWuPopi'],
    maybeSwitchTo: []
  },
  'litshed-school-20-usd': {
    id: 'litshed-school-20-usd',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'usd',
    classes: 20,
    teachers: 80,
    basePrice: 590,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (20 Classes)',
    canSwitchTo: ['price_1Gu4FLEwnA0RkkU7KnWuPopi'],
    maybeSwitchTo: []
  },
  'litshed-school-21-usd': {
    id: 'litshed-school-21-usd',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'usd',
    classes: 21,
    teachers: 84,
    basePrice: 619.5,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (21 Classes)',
    canSwitchTo: ['price_1Gu4FLEwnA0RkkU7KnWuPopi'],
    maybeSwitchTo: []
  },
  'litshed-school-22-usd': {
    id: 'litshed-school-22-usd',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'usd',
    classes: 22,
    teachers: 88,
    basePrice: 649,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (22 Classes)',
    canSwitchTo: ['price_1Gu4FLEwnA0RkkU7KnWuPopi'],
    maybeSwitchTo: []
  },
  'litshed-school-23-usd': {
    id: 'litshed-school-23-usd',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'usd',
    classes: 23,
    teachers: 92,
    basePrice: 678.5,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (23 Classes)',
    canSwitchTo: ['price_1Gu4FLEwnA0RkkU7KnWuPopi'],
    maybeSwitchTo: []
  },
  'litshed-school-24-usd': {
    id: 'litshed-school-24-usd',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'usd',
    classes: 24,
    teachers: 96,
    basePrice: 708,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (24 Classes)',
    canSwitchTo: ['price_1Gu4FLEwnA0RkkU7KnWuPopi'],
    maybeSwitchTo: []
  },
  'litshed-school-25-usd': {
    id: 'litshed-school-25-usd',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'usd',
    classes: 25,
    teachers: 100,
    basePrice: 737.5,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (25 Classes)',
    canSwitchTo: ['price_1Gu4FLEwnA0RkkU7KnWuPopi'],
    maybeSwitchTo: []
  },
  'litshed-school-26-usd': {
    id: 'litshed-school-26-usd',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'usd',
    classes: 26,
    teachers: 104,
    basePrice: 767,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (26 Classes)',
    canSwitchTo: ['price_1Gu4FLEwnA0RkkU7KnWuPopi'],
    maybeSwitchTo: []
  },
  'litshed-school-27-usd': {
    id: 'litshed-school-27-usd',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'usd',
    classes: 27,
    teachers: 108,
    basePrice: 796.5,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (27 Classes)',
    canSwitchTo: ['price_1Gu4FLEwnA0RkkU7KnWuPopi'],
    maybeSwitchTo: []
  },
  'litshed-school-28-usd': {
    id: 'litshed-school-28-usd',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'usd',
    classes: 28,
    teachers: 112,
    basePrice: 826,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (28 Classes)',
    canSwitchTo: ['price_1Gu4FLEwnA0RkkU7KnWuPopi'],
    maybeSwitchTo: []
  },
  'litshed-school-29-usd': {
    id: 'litshed-school-29-usd',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'usd',
    classes: 29,
    teachers: 116,
    basePrice: 855.5,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (29 Classes)',
    canSwitchTo: ['price_1Gu4FLEwnA0RkkU7KnWuPopi'],
    maybeSwitchTo: []
  },
  'litshed-school-30-usd': {
    id: 'litshed-school-30-usd',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'usd',
    classes: 30,
    teachers: 120,
    basePrice: 885,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (30 Classes)',
    canSwitchTo: ['price_1Gu4FLEwnA0RkkU7KnWuPopi'],
    maybeSwitchTo: []
  },
  'litshed-school-31-usd': {
    id: 'litshed-school-31-usd',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'usd',
    classes: 31,
    teachers: 124,
    basePrice: 914.5,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (31 Classes)',
    canSwitchTo: ['price_1Gu4FLEwnA0RkkU7KnWuPopi'],
    maybeSwitchTo: []
  },
  'litshed-school-32-usd': {
    id: 'litshed-school-32-usd',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'usd',
    classes: 32,
    teachers: 128,
    basePrice: 944,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (32 Classes)',
    canSwitchTo: ['price_1Gu4FLEwnA0RkkU7KnWuPopi'],
    maybeSwitchTo: []
  },
  'litshed-school-33-usd': {
    id: 'litshed-school-33-usd',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'usd',
    classes: 33,
    teachers: 132,
    basePrice: 973.5,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (33 Classes)',
    canSwitchTo: ['price_1Gu4FLEwnA0RkkU7KnWuPopi'],
    maybeSwitchTo: []
  },
  'litshed-school-34-usd': {
    id: 'litshed-school-34-usd',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'usd',
    classes: 34,
    teachers: 136,
    basePrice: 1003,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (34 Classes)',
    canSwitchTo: ['price_1Gu4FLEwnA0RkkU7KnWuPopi'],
    maybeSwitchTo: []
  },
  'litshed-school-35-usd': {
    id: 'litshed-school-35-usd',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'usd',
    classes: 35,
    teachers: 140,
    basePrice: 1032.5,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (35 Classes)',
    canSwitchTo: ['price_1Gu4FLEwnA0RkkU7KnWuPopi'],
    maybeSwitchTo: []
  },
  'litshed-school-36-usd': {
    id: 'litshed-school-36-usd',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'usd',
    classes: 36,
    teachers: 144,
    basePrice: 1062,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (36 Classes)',
    canSwitchTo: ['price_1Gu4FLEwnA0RkkU7KnWuPopi'],
    maybeSwitchTo: []
  },
  'litshed-school-37-usd': {
    id: 'litshed-school-37-usd',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'usd',
    classes: 37,
    teachers: 148,
    basePrice: 1091.5,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (37 Classes)',
    canSwitchTo: ['price_1Gu4FLEwnA0RkkU7KnWuPopi'],
    maybeSwitchTo: []
  },
  'litshed-school-38-usd': {
    id: 'litshed-school-38-usd',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'usd',
    classes: 38,
    teachers: 152,
    basePrice: 1121,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (38 Classes)',
    canSwitchTo: ['price_1Gu4FLEwnA0RkkU7KnWuPopi'],
    maybeSwitchTo: []
  },
  'litshed-school-39-usd': {
    id: 'litshed-school-39-usd',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'usd',
    classes: 39,
    teachers: 156,
    basePrice: 1150.5,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (39 Classes)',
    canSwitchTo: ['price_1Gu4FLEwnA0RkkU7KnWuPopi'],
    maybeSwitchTo: []
  },
  'litshed-school-40-usd': {
    id: 'litshed-school-40-usd',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'usd',
    classes: 40,
    teachers: 160,
    basePrice: 1180,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - School (40 Classes)',
    canSwitchTo: ['price_1Gu4FLEwnA0RkkU7KnWuPopi'],
    maybeSwitchTo: []
  },
  'litshed-personal': {
    id: 'litshed-personal',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: false,
    currency: 'gbp',
    teachers: 1,
    basePrice: 20.825,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - Personal',
    canSwitchTo: [],
    maybeSwitchTo: [
      {
        plan: 'litshed-metered-school-gbp',
        condition: ['org_type', '=', 'business']
      },
      {
        plan: 'litshed-metered-school-gbp',
        condition: ['org_type', '=', 'school']
      }
    ]
  },
  'litshed-personal-mar22': {
    id: 'litshed-personal-mar22',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',
    school: false,
    currency: 'gbp',
    teachers: 1,
    basePrice: 22.917,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - Personal',
    canSwitchTo: [],
    maybeSwitchTo: [
      {
        condition: ['org_type', '=', 'school'],
        plan: 'litshed-metered-school-gbp-mar22'
      }
    ]
  },
  'litshed-personal-usd': {
    id: 'litshed-personal-usd',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',
    school: false,
    currency: 'usd',
    teachers: 1,
    basePrice: 34.99,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - Personal',
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'litshed-personal-zar': {
    id: 'litshed-personal-zar',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: false,
    currency: 'zar',
    teachers: 1,
    basePrice: 460,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - Personal',
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  price_1Gu4ICEwnA0RkkU7WnYDEbrQ: {
    id: 'price_1Gu4ICEwnA0RkkU7WnYDEbrQ',
    accountRegion: 'US',
    group: 'litshed',
    period: 'year',

    school: false,
    currency: 'usd',
    teachers: 1,
    basePrice: 34.99,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - Personal',
    test: false,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'litshed-personal-eur': {
    id: 'litshed-personal-eur',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: false,
    currency: 'eur',
    teachers: 1,
    basePrice: 29.99,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - Personal',
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'litshed-personal-aud': {
    id: 'litshed-personal-aud',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: false,
    currency: 'aud',
    teachers: 1,
    basePrice: 49.99,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - Personal',
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'litshed-personal-nzd': {
    id: 'litshed-personal-nzd',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: false,
    currency: 'nzd',
    teachers: 1,
    basePrice: 49.99,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - Personal',
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'litshed-metered-school-gbp': {
    id: 'litshed-metered-school-gbp',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',
    school: true,
    currency: 'gbp',
    classes: 1,
    teachers: 4,
    qtyMin: 2,
    qtyMax: 40,
    basePrice: 39.00,
    priceType: 'steps',
    priceTiers: [{ to: null, cost: 19.50 }],
    title: 'Literacy Shed Plus - School',
    metered_entity: 'class',
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'litshed-metered-school-usd': {
    id: 'litshed-metered-school-usd',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'usd',
    classes: 1,
    teachers: 4,
    qtyMin: 2,
    qtyMax: 40,
    basePrice: 59.00,
    priceType: 'steps',
    priceTiers: [{ to: null, cost: 29.50 }],
    title: 'Literacy Shed Plus - School',
    metered_entity: 'class',
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'litshed-metered-school-zar': {
    id: 'litshed-metered-school-zar',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'zar',
    classes: 1,
    teachers: 4,
    qtyMin: 2,
    qtyMax: 40,
    basePrice: 860,
    priceType: 'steps',
    priceTiers: [{ to: null, cost: 430 }],
    title: 'Literacy Shed Plus - School',
    metered_entity: 'class',
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'litshed-metered-school-gbp-mar22': {
    id: 'litshed-metered-school-gbp-mar22',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'gbp',
    classes: 1,
    teachers: 4,
    qtyMin: 2,
    qtyMax: 40,
    basePrice: 43.00,
    priceType: 'steps',
    priceTiers: [{ to: null, cost: 21.50 }],
    title: 'Literacy Shed Plus - School',
    metered_entity: 'class',
    canSwitchTo: [],
    maybeSwitchTo: []
  },

  price_1Gu4ICEwnA0RkkU7PYW0jvUh: {
    id: 'price_1Gu4ICEwnA0RkkU7PYW0jvUh',
    accountRegion: 'US',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'usd',
    classes: 1,
    teachers: 4,
    qtyMin: 2,
    qtyMax: 40,
    basePrice: 59.00,
    priceType: 'steps',
    priceTiers: [{ to: null, cost: 29.50 }],
    title: 'Literacy Shed Plus - School',
    test: false,
    metered_entity: 'class',
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'litshed-metered-school-eur': {
    id: 'litshed-metered-school-eur',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'eur',
    classes: 1,
    teachers: 4,
    qtyMin: 2,
    qtyMax: 40,
    basePrice: 48.00,
    priceType: 'steps',
    priceTiers: [{ to: null, cost: 24.00 }],
    title: 'Literacy Shed Plus - School',
    metered_entity: 'class',
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'litshed-metered-school-aud': {
    id: 'litshed-metered-school-aud',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'aud',
    classes: 1,
    teachers: 4,
    qtyMin: 2,
    qtyMax: 40,
    basePrice: 80.00,
    priceType: 'steps',
    priceTiers: [{ to: null, cost: 40.00 }],
    title: 'Literacy Shed Plus - School',
    metered_entity: 'class',
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'litshed-metered-school-nzd': {
    id: 'litshed-metered-school-nzd',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'nzd',
    classes: 1,
    teachers: 4,
    qtyMin: 2,
    qtyMax: 40,
    basePrice: 80.00,
    priceType: 'steps',
    priceTiers: [{ to: null, cost: 40.00 }],
    title: 'Literacy Shed Plus - School',
    metered_entity: 'class',
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'edshed-metered-spelling-home-yearly-gbp': {
    id: 'edshed-metered-spelling-home-yearly-gbp',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: false,
    currency: 'gbp',
    qtyMin: 5,
    basePrice: 24.99,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 4.998 }, { to: null, cost: 5.00 }],
    title: 'Spelling Shed - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-yearly-gbp'],
    maybeSwitchTo: []
  },
  'edshed-metered-spelling-home-monthly-gbp': {
    id: 'edshed-metered-spelling-home-monthly-gbp',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'month',

    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: false,
    currency: 'gbp',
    qtyMin: 5,
    basePrice: 4.16,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 0.832 }, { to: null, cost: 0.83 }],
    title: 'Spelling Shed - Home Monthly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-monthly-gbp'],
    maybeSwitchTo: [
      {
        plan: 'edshed-metered-spelling-school-yearly-gbp',
        condition: ['org_type', '=', 'business']
      },
      {
        plan: 'edshed-metered-combined-school-yearly-gbp',
        condition: ['org_type', '=', 'business']
      }
    ]
  },
  'edshed-metered-number-home-yearly-gbp': {
    id: 'edshed-metered-number-home-yearly-gbp',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'gbp',
    qtyMin: 5,
    basePrice: 24.99,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 4.998 }, { to: null, cost: 5.00 }],
    title: 'MathShed - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-yearly-gbp'],
    maybeSwitchTo: []
  },
  'edshed-metered-number-home-monthly-gbp': {
    id: 'edshed-metered-number-home-monthly-gbp',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'month',

    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'gbp',
    basePrice: 4.16,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 0.832 }, { to: null, cost: 0.83 }],
    qtyMin: 5,
    title: 'MathShed - Home Monthly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-monthly-gbp'],
    maybeSwitchTo: [
      {
        plan: 'edshed-metered-number-school-yearly-gbp',
        condition: ['org_type', '=', 'business']
      },
      {
        plan: 'edshed-metered-combined-school-yearly-gbp',
        condition: ['org_type', '=', 'business']
      }
    ]
  },
  'edshed-metered-combined-home-yearly-gbp': {
    id: 'edshed-metered-combined-home-yearly-gbp',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'gbp',
    basePrice: 42.49,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 8.498 }, { to: null, cost: 8.50 }],
    qtyMin: 5,
    title: 'EdShed Online Combined - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'edshed-metered-combined-home-monthly-gbp': {
    id: 'edshed-metered-combined-home-monthly-gbp',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'month',

    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'gbp',
    basePrice: 7.07,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 1.414 }, { to: null, cost: 1.42 }],
    qtyMin: 5,
    title: 'EdShed Online Combined - Home Monthly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: [
      {
        plan: 'edshed-metered-combined-school-yearly-gbp',
        condition: ['org_type', '=', 'business']
      }
    ]
  },
  'edshed-metered-spelling-home-yearly-usd': {
    id: 'edshed-metered-spelling-home-yearly-usd',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: false,
    currency: 'usd',
    basePrice: 24.95,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 4.99 }, { to: null, cost: 4.99 }],
    qtyMin: 5,
    title: 'Spelling Shed - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-yearly-usd'],
    maybeSwitchTo: []
  },
  'edshed-metered-number-home-yearly-usd': {
    id: 'edshed-metered-number-home-yearly-usd',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'usd',
    basePrice: 24.95,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 4.99 }, { to: null, cost: 4.99 }],
    qtyMin: 5,
    title: 'MathShed - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-yearly-usd'],
    maybeSwitchTo: []
  },
  'edshed-metered-combined-home-yearly-usd': {
    id: 'edshed-metered-combined-home-yearly-usd',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'usd',
    basePrice: 42.42, // + VAT = 29.99
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 8.484 }, { to: null, cost: 8.49 }],
    qtyMin: 5,
    title: 'EdShed Online Combined - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: []
  },

  price_1Gu4agEwnA0RkkU71DwvmCjz: {
    id: 'price_1Gu4agEwnA0RkkU71DwvmCjz',
    accountRegion: 'US',
    group: 'edshed',
    period: 'year',
    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: false,
    currency: 'usd',
    basePrice: 24.95,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 4.99 }, { to: null, cost: 4.99 }],
    qtyMin: 5,
    title: 'Spelling Shed - Home Yearly',
    subtitle: 'Up to 5 Children',
    test: false,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['price_1Gu4agEwnA0RkkU7dBzKhlVh'],
    maybeSwitchTo: []
  },
  price_1Gu4agEwnA0RkkU717cgpeI8: {
    id: 'price_1Gu4agEwnA0RkkU717cgpeI8',
    accountRegion: 'US',
    group: 'edshed',
    period: 'year',

    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'usd',
    basePrice: 24.95,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 4.99 }, { to: null, cost: 4.99 }],
    qtyMin: 5,
    title: 'MathShed - Home Yearly',
    subtitle: 'Up to 5 Children',
    test: false,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['price_1Gu4agEwnA0RkkU7dBzKhlVh'],
    maybeSwitchTo: []
  },
  price_1Gu4agEwnA0RkkU7dBzKhlVh: {
    id: 'price_1Gu4agEwnA0RkkU7dBzKhlVh',
    accountRegion: 'US',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'usd',
    basePrice: 42.42, // + VAT = 29.99
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 8.484 }, { to: null, cost: 8.49 }],
    qtyMin: 5,
    title: 'EdShed Online Combined - Home Yearly',
    subtitle: 'Up to 5 Children',
    test: false,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'edshed-metered-spelling-home-yearly-eur': {
    id: 'edshed-metered-spelling-home-yearly-eur',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: false,
    currency: 'eur',
    basePrice: 29.99,
    qtyMin: 5,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 5.998 }, { to: null, cost: 6.00 }],
    title: 'Spelling Shed - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-yearly-eur'],
    maybeSwitchTo: []
  },
  'edshed-metered-number-home-yearly-eur': {
    id: 'edshed-metered-number-home-yearly-eur',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'eur',
    basePrice: 29.99,
    qtyMin: 5,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 5.998 }, { to: null, cost: 6.00 }],
    title: 'MathShed - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-yearly-eur'],
    maybeSwitchTo: []
  },
  'edshed-metered-combined-home-yearly-eur': {
    id: 'edshed-metered-combined-home-yearly-eur',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'eur',
    basePrice: 50.98,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 10.196 }, { to: null, cost: 10.20 }],
    qtyMin: 5,
    title: 'EdShed Online Combined - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'edshed-metered-spelling-home-yearly-aud': {
    id: 'edshed-metered-spelling-home-yearly-aud',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: false,
    currency: 'aud',
    basePrice: 29.99,
    qtyMin: 5,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 5.998 }, { to: null, cost: 6.00 }],
    title: 'Spelling Shed - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-yearly-aud'],
    maybeSwitchTo: []
  },
  'edshed-metered-number-home-yearly-aud': {
    id: 'edshed-metered-number-home-yearly-aud',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'aud',
    basePrice: 29.99,
    qtyMin: 5,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 5.998 }, { to: null, cost: 6.00 }],
    title: 'MathShed - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-yearly-aud'],
    maybeSwitchTo: []
  },
  'edshed-metered-combined-home-yearly-aud': {
    id: 'edshed-metered-combined-home-yearly-aud',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'aud',
    basePrice: 50.98,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 10.196 }, { to: null, cost: 10.20 }],
    qtyMin: 5,
    title: 'EdShed Online Combined - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'edshed-metered-spelling-home-yearly-nzd': {
    id: 'edshed-metered-spelling-home-yearly-nzd',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: false,
    currency: 'nzd',
    basePrice: 29.99,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 5.998 }, { to: null, cost: 6.00 }],
    qtyMin: 5,
    title: 'Spelling Shed - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-yearly-nzd'],
    maybeSwitchTo: []
  },
  'edshed-metered-number-home-yearly-nzd': {
    id: 'edshed-metered-number-home-yearly-nzd',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'nzd',
    basePrice: 29.99,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 5.998 }, { to: null, cost: 6.00 }],
    qtyMin: 5,
    title: 'MathShed - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-yearly-nzd'],
    maybeSwitchTo: []
  },
  'edshed-metered-combined-home-yearly-nzd': {
    id: 'edshed-metered-combined-home-yearly-nzd',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'nzd',
    basePrice: 50.98,
    qtyMin: 5,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 10.196 }, { to: null, cost: 10.20 }],
    title: 'EdShed Online Combined - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'edshed-metered-spelling-school-yearly-gbp': {
    id: 'edshed-metered-spelling-school-yearly-gbp',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: true,
    currency: 'gbp',
    basePrice: 30.00,
    priceType: 'steps',
    priceTiers: [{ to: 36, cost: 0.83333333 }, { to: 199, cost: 0.83 }, { to: 399, cost: 0.75 }, { to: 1999, cost: 0.70 }, { to: null, cost: 0.65 }],
    qtyMin: 36,
    title: 'Spelling Shed - Yearly',
    subtitle: 'From 36 Pupils.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-gbp'],
    maybeSwitchTo: []
  },
  'edshed-metered-number-school-yearly-gbp': {
    id: 'edshed-metered-number-school-yearly-gbp',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'gbp',
    basePrice: 30.00,
    priceType: 'steps',
    priceTiers: [{ to: 36, cost: 0.83333333 }, { to: 199, cost: 0.83 }, { to: 399, cost: 0.75 }, { to: 1999, cost: 0.70 }, { to: null, cost: 0.65 }],
    qtyMin: 36,
    title: 'MathShed - Yearly',
    subtitle: 'From 36 Pupils.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-gbp'],
    maybeSwitchTo: []
  },
  'edshed-metered-combined-school-yearly-gbp': {
    id: 'edshed-metered-combined-school-yearly-gbp',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'gbp',
    basePrice: 50.00,
    priceType: 'steps',
    priceTiers: [{ to: 36, cost: 1.38888889 }, { to: 199, cost: 1.38 }, { to: 399, cost: 1.25 }, { to: 1999, cost: 1.17 }, { to: null, cost: 1.08 }],
    qtyMin: 36,
    title: 'EdShed Online Combined - Yearly',
    subtitle: 'From 36 Pupils.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'edshed-metered-spelling-school-yearly-usd': {
    id: 'edshed-metered-spelling-school-yearly-usd',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: true,
    currency: 'usd',
    basePrice: 56.00,
    priceType: 'steps',
    priceTiers: [{ to: 499, cost: 2.00 }, { to: 1999, cost: 1.85 }, { to: null, cost: 1.75 }],
    qtyMin: 28,
    title: 'Spelling Shed - Yearly',
    subtitle: 'From 28 Students.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-usd'],
    maybeSwitchTo: []
  },
  'edshed-metered-number-school-yearly-usd': {
    id: 'edshed-metered-number-school-yearly-usd',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'usd',
    basePrice: 56.00,
    priceType: 'steps',
    priceTiers: [{ to: 499, cost: 2.00 }, { to: 1999, cost: 1.85 }, { to: null, cost: 1.75 }],
    qtyMin: 28,
    title: 'MathShed - Yearly',
    subtitle: 'From 28 Students.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-usd'],
    maybeSwitchTo: []
  },
  'edshed-metered-combined-school-yearly-usd': {
    id: 'edshed-metered-combined-school-yearly-usd',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'usd',
    basePrice: 95.20,
    priceType: 'steps',
    priceTiers: [{ to: 499, cost: 3.40 }, { to: 1999, cost: 3.15 }, { to: null, cost: 3.00 }],
    qtyMin: 28,
    title: 'EdShed Online Combined - Yearly',
    subtitle: 'From 28 Students.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: []
  },

  'edshed-metered-spelling-school-yearly-gbp-mar22': {
    id: 'edshed-metered-spelling-school-yearly-gbp-mar22',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: true,
    currency: 'gbp',
    basePrice: 49.99,
    priceType: 'steps',
    priceTiers: [{ to: 36, cost: 1.388657 }, { to: 199, cost: 1.50 }, { to: 399, cost: 1.35 }, { to: 1999, cost: 1.25 }, { to: null, cost: 1.15 }],
    qtyMin: 36,
    title: 'Spelling Shed - Yearly',
    subtitle: 'From 36 Pupils.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-gbp-mar22'],
    maybeSwitchTo: []
  },
  'edshed-metered-number-school-yearly-gbp-mar22': {
    id: 'edshed-metered-number-school-yearly-gbp-mar22',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'gbp',
    basePrice: 49.99,
    priceType: 'steps',
    priceTiers: [{ to: 36, cost: 1.388657 }, { to: 199, cost: 1.50 }, { to: 399, cost: 1.35 }, { to: 1999, cost: 1.25 }, { to: null, cost: 1.15 }],
    qtyMin: 36,
    title: 'MathShed - Yearly',
    subtitle: 'From 36 Pupils.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-gbp-mar22'],
    maybeSwitchTo: []
  },
  'edshed-metered-combined-school-yearly-gbp-mar22': {
    id: 'edshed-metered-combined-school-yearly-gbp-mar22',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'gbp',
    basePrice: 83.325,
    priceType: 'steps',
    priceTiers: [{ to: 36, cost: 2.36083 }, { to: 199, cost: 2.50 }, { to: 399, cost: 2.25 }, { to: 1999, cost: 2.11 }, { to: null, cost: 1.95 }],
    qtyMin: 36,
    title: 'EdShed Online Combined - Yearly',
    subtitle: 'From 36 Pupils.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: []
  },

  price_1Gu4agEwnA0RkkU7GPfLLid4: {
    id: 'price_1Gu4agEwnA0RkkU7GPfLLid4',
    accountRegion: 'US',
    group: 'edshed',
    period: 'year',
    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: true,
    currency: 'usd',
    basePrice: 56.00,
    priceType: 'steps',
    priceTiers: [{ to: 499, cost: 2.00 }, { to: 1999, cost: 1.85 }, { to: null, cost: 1.75 }],
    qtyMin: 28,
    title: 'Spelling Shed - Yearly',
    subtitle: 'From 28 Students.',
    test: false,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['price_1Gu4agEwnA0RkkU7wVvr6g8W'],
    maybeSwitchTo: []
  },
  price_1Gu4agEwnA0RkkU7Cujvtk7w: {
    id: 'price_1Gu4agEwnA0RkkU7Cujvtk7w',
    accountRegion: 'US',
    group: 'edshed',
    period: 'year',
    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'usd',
    basePrice: 56.00,
    priceType: 'steps',
    priceTiers: [{ to: 499, cost: 2.00 }, { to: 1999, cost: 1.85 }, { to: null, cost: 1.75 }],
    qtyMin: 28,
    title: 'MathShed - Yearly',
    subtitle: 'From 28 Students.',
    test: false,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['price_1Gu4agEwnA0RkkU7wVvr6g8W'],
    maybeSwitchTo: []
  },
  price_1Gu4agEwnA0RkkU7wVvr6g8W: {
    id: 'price_1Gu4agEwnA0RkkU7wVvr6g8W',
    accountRegion: 'US',
    group: 'edshed',
    period: 'year',
    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'usd',
    basePrice: 95.20,
    priceType: 'steps',
    priceTiers: [{ to: 499, cost: 3.40 }, { to: 1999, cost: 3.15 }, { to: null, cost: 3.00 }],
    qtyMin: 28,
    title: 'EdShed Online Combined - Yearly',
    subtitle: 'From 28 Students.',
    test: false,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'edshed-metered-spelling-school-yearly-eur': {
    id: 'edshed-metered-spelling-school-yearly-eur',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: true,
    currency: 'eur',
    basePrice: 60.00,
    priceType: 'steps',
    priceTiers: [{ to: 36, cost: 1.66666 }, { to: 199, cost: 1.66 }, { to: 399, cost: 1.5 }, { to: 1999, cost: 1.40 }, { to: null, cost: 1.30 }],
    qtyMin: 36,
    title: 'Spelling Shed - Yearly',
    subtitle: 'From 36 Students.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-eur'],
    maybeSwitchTo: []
  },
  'edshed-metered-number-school-yearly-eur': {
    id: 'edshed-metered-number-school-yearly-eur',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'eur',
    basePrice: 60.00,
    priceType: 'steps',
    priceTiers: [{ to: 36, cost: 1.66666 }, { to: 199, cost: 1.66 }, { to: 399, cost: 1.5 }, { to: 1999, cost: 1.40 }, { to: null, cost: 1.30 }],
    qtyMin: 36,
    title: 'MathShed - Yearly',
    subtitle: 'From 36 Students.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-eur'],
    maybeSwitchTo: []
  },
  'edshed-metered-combined-school-yearly-eur': {
    id: 'edshed-metered-combined-school-yearly-eur',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'eur',
    basePrice: 100.00,
    priceType: 'steps',
    priceTiers: [{ to: 36, cost: 2.77776667 }, { to: 199, cost: 2.77 }, { to: 399, cost: 2.5 }, { to: 1999, cost: 2.34 }, { to: null, cost: 2.16 }],
    qtyMin: 36,
    title: 'EdShed Online Combined - Yearly',
    subtitle: 'From 36 Students.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'edshed-metered-spelling-school-yearly-aud': {
    id: 'edshed-metered-spelling-school-yearly-aud',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: true,
    currency: 'aud',
    basePrice: 84.00,
    priceType: 'steps',
    priceTiers: [{ to: 499, cost: 3.00 }, { to: 1999, cost: 2.78 }, { to: null, cost: 2.63 }],
    qtyMin: 28,
    title: 'Spelling Shed - Yearly',
    subtitle: 'From 28 Students.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-aud'],
    maybeSwitchTo: []
  },
  'edshed-metered-number-school-yearly-aud': {
    id: 'edshed-metered-number-school-yearly-aud',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'aud',
    basePrice: 84.00,
    priceType: 'steps',
    priceTiers: [{ to: 499, cost: 3.00 }, { to: 1999, cost: 2.78 }, { to: null, cost: 2.63 }],
    qtyMin: 28,
    title: 'MathShed - Yearly',
    subtitle: 'From 28 Students.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-aud'],
    maybeSwitchTo: []
  },
  'edshed-metered-combined-school-yearly-aud': {
    id: 'edshed-metered-combined-school-yearly-aud',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'aud',
    basePrice: 140.00,
    priceType: 'steps',
    priceTiers: [{ to: 499, cost: 5.00 }, { to: 1999, cost: 4.63 }, { to: null, cost: 4.38 }],
    qtyMin: 28,
    title: 'EdShed Online Combined - Yearly',
    subtitle: 'From 28 Students.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'edshed-metered-spelling-school-yearly-nzd': {
    id: 'edshed-metered-spelling-school-yearly-nzd',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: true,
    currency: 'nzd',
    basePrice: 84.00,
    priceType: 'steps',
    priceTiers: [{ to: 499, cost: 3.00 }, { to: 1999, cost: 2.78 }, { to: null, cost: 2.63 }],
    qtyMin: 28,
    title: 'Spelling Shed - Yearly',
    subtitle: 'From 28 Students.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-nzd'],
    maybeSwitchTo: []
  },
  'edshed-metered-number-school-yearly-nzd': {
    id: 'edshed-metered-number-school-yearly-nzd',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'nzd',
    basePrice: 84.00,
    priceType: 'steps',
    priceTiers: [{ to: 499, cost: 3.00 }, { to: 1999, cost: 2.78 }, { to: null, cost: 2.63 }],
    qtyMin: 28,
    title: 'MathShed - Yearly',
    subtitle: 'From 28 Students.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-nzd'],
    maybeSwitchTo: []
  },
  'edshed-metered-combined-school-yearly-nzd': {
    id: 'edshed-metered-combined-school-yearly-nzd',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'nzd',
    qtyMin: 28,
    basePrice: 140.00,
    priceType: 'steps',
    priceTiers: [{ to: 499, cost: 5.00 }, { to: 1999, cost: 4.63 }, { to: null, cost: 4.38 }],
    title: 'EdShed Online Combined - Yearly',
    subtitle: 'From 28 Students.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  price_1KQUzHEwnA0RkkU7yJCghvz9: {
    id: 'price_1KQUzHEwnA0RkkU7yJCghvz9',
    accountRegion: 'US',
    group: 'edshed',
    period: 'year',

    spelling: false,
    number: false,
    phonics: false,
    phonics_lite_1: true,
    premium: true,
    school: true,
    currency: 'usd',
    teachers: 1,
    basePrice: 99.00,
    priceType: 'fixed',
    title: 'EdShed Online Phonics Intervention Pack 1 - Yearly',
    metered_entity: 'teacher',
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  price_1KQUzZEwnA0RkkU7RfdBlZyA: {
    id: 'price_1KQUzZEwnA0RkkU7RfdBlZyA',
    accountRegion: 'US',
    group: 'edshed',
    period: 'year',

    spelling: false,
    number: false,
    phonics: false,
    phonics_lite_2: true,
    premium: true,
    school: true,
    currency: 'usd',
    teachers: 1,
    basePrice: 99.00,
    priceType: 'fixed',
    title: 'EdShed Online Phonics Intervention Pack 2 - Yearly',
    metered_entity: 'teacher',
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  price_1KQV0CEwnA0RkkU7YXkuPJYj: {
    id: 'price_1KQV0CEwnA0RkkU7YXkuPJYj',
    accountRegion: 'US',
    group: 'edshed',
    period: 'year',

    spelling: false,
    number: false,
    phonics: false,
    phonics_lite_1: true,
    phonics_lite_2: true,
    premium: true,
    school: true,
    currency: 'usd',
    teachers: 1,
    basePrice: 149.00,
    priceType: 'fixed',
    title: 'EdShed Online Phonics Intervention Pack 1 + 2 - Yearly',
    metered_entity: 'teacher',
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'edshed-metered-phonics-yearly-gbp': {
    id: 'edshed-metered-phonics-yearly-gbp',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: false,
    phonics: true,
    premium: true,
    school: true,
    currency: 'gbp',
    pupils: 36,
    teachers: 4,
    classes: 1,
    basePrice: 50.00,
    priceType: 'steps',
    priceTiers: [{ to: null, cost: 50.00 }],
    title: 'EdShed Online Phonics - Yearly',
    metered_entity: 'class',
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'edshed-metered-phonics-digital-yearly-gbp': {
    id: 'edshed-metered-phonics-digital-yearly-gbp',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: false,
    phonics: true,
    premium: true,
    school: true,
    currency: 'gbp',
    pupils: 36,
    teachers: 4,
    classes: 1,
    basePrice: 150.00,
    priceType: 'steps',
    priceTiers: [{ to: null, cost: 150.00 }],
    title: 'EdShed Online Phonics Digital - Yearly',
    metered_entity: 'class',
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'edshed-metered-phonics-digital-yearly-eur': {
    id: 'edshed-metered-phonics-digital-yearly-eur',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: false,
    phonics: true,
    premium: true,
    school: true,
    currency: 'eur',
    pupils: 36,
    teachers: 4,
    classes: 1,
    basePrice: 200.00,
    priceType: 'steps',
    priceTiers: [{ to: null, cost: 200.00 }],
    title: 'EdShed Online Phonics Digital - Yearly',
    metered_entity: 'class',
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'edshed-metered-phonics-digital-yearly-aud': {
    id: 'edshed-metered-phonics-digital-yearly-aud',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: false,
    phonics: true,
    premium: true,
    school: true,
    currency: 'aud',
    pupils: 36,
    teachers: 4,
    classes: 1,
    basePrice: 300.00,
    priceType: 'steps',
    priceTiers: [{ to: null, cost: 300.00 }],
    title: 'EdShed Online Phonics Digital - Yearly',
    metered_entity: 'class',
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'edshed-metered-phonics-digital-yearly-nzd': {
    id: 'edshed-metered-phonics-digital-yearly-nzd',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: false,
    phonics: true,
    premium: true,
    school: true,
    currency: 'nzd',
    pupils: 36,
    teachers: 4,
    classes: 1,
    basePrice: 300.00,
    priceType: 'steps',
    priceTiers: [{ to: null, cost: 300.00 }],
    title: 'EdShed Online Phonics Digital - Yearly',
    metered_entity: 'class',
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  price_1L3dqNEwnA0RkkU70Qbwf9NX: {
    id: 'price_1L3dqNEwnA0RkkU70Qbwf9NX',
    accountRegion: 'US',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: false,
    phonics: true,
    phonics_lite_1: true,
    phonics_lite_2: true,
    premium: true,
    school: true,
    currency: 'usd',
    pupils: 36,
    teachers: 4,
    classes: 1,
    basePrice: 249.00,
    priceType: 'steps',
    priceTiers: [{ to: null, cost: 249.00 }],
    title: 'EdShed Online Phonics Digital - Yearly - US',
    metered_entity: 'class',
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'edshed-metered-combined-school-yearly-zar': {
    id: 'edshed-metered-combined-school-yearly-zar',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: true,
    phonics: true,
    premium: true,
    school: true,
    currency: 'zar',
    basePrice: 1836.00,
    priceType: 'steps',
    priceTiers: [{ to: 36, cost: 51 }, { to: 199, cost: 51 }, { to: 399, cost: 45.90 }, { to: 1999, cost: 42.50 }, { to: null, cost: 39.10 }],
    qtyMin: 36,
    title: 'Spelling Shed, MathShed & Phonics Shed - Yearly',
    subtitle: 'From 36 Students.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'edshed-metered-spelling-school-yearly-zar': {
    id: 'edshed-metered-spelling-school-yearly-zar',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: false,
    phonics: true,
    premium: true,
    school: true,
    currency: 'zar',
    basePrice: 1080.00,
    priceType: 'steps',
    priceTiers: [{ to: 36, cost: 30 }, { to: 199, cost: 30 }, { to: 399, cost: 27 }, { to: 1999, cost: 25 }, { to: null, cost: 23 }],
    qtyMin: 36,
    title: 'Spelling Shed and Phonics Shed - Yearly',
    subtitle: 'From 36 Students.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-zar'],
    maybeSwitchTo: []
  },
  'edshed-metered-number-school-yearly-zar': {
    id: 'edshed-metered-number-school-yearly-zar',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'zar',
    basePrice: 1080.00,
    priceType: 'steps',
    priceTiers: [{ to: 36, cost: 30 }, { to: 199, cost: 30 }, { to: 399, cost: 27 }, { to: 1999, cost: 25 }, { to: null, cost: 23 }],
    qtyMin: 36,
    title: 'Spelling Shed - Yearly',
    subtitle: 'From 36 Students.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-zar'],
    maybeSwitchTo: []
  },
  'edshed-metered-phonics-yearly-zar': {
    id: 'edshed-metered-phonics-yearly-zar',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: false,
    phonics: true,
    premium: true,
    school: true,
    currency: 'zar',
    pupils: 36,
    teachers: 4,
    classes: 1,
    basePrice: 3000.00,
    priceType: 'steps',
    priceTiers: [{ to: null, cost: 3000.00 }],
    title: 'EdShed Online Phonics Digital - Yearly',
    metered_entity: 'class',
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'edshed-metered-spelling-home-yearly-zar': {
    id: 'edshed-metered-spelling-home-yearly-zar',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: false,
    phonics: true,
    premium: true,
    school: false,
    currency: 'zar',
    qtyMin: 5,
    basePrice: 500,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 100 }, { to: null, cost: 100 }],
    title: 'Spelling Shed & Phonics Shed - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-yearly-zar'],
    maybeSwitchTo: []
  },
  'edshed-metered-number-home-yearly-zar': {
    id: 'edshed-metered-number-home-yearly-zar',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'zar',
    qtyMin: 5,
    basePrice: 500,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 100 }, { to: null, cost: 100 }],
    title: 'MathShed - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-yearly-zar'],
    maybeSwitchTo: []
  },
  'edshed-metered-combined-home-yearly-zar': {
    id: 'edshed-metered-combined-home-yearly-zar',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: true,
    phonics: true,
    premium: true,
    school: false,
    currency: 'zar',
    qtyMin: 5,
    basePrice: 850,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 170 }, { to: null, cost: 170 }],
    title: 'Spelling Shed, MathShed & Phonics Shed - Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'edshed-metered-spelling-school-yearly-gbp-jan23': {
    id: 'edshed-metered-spelling-school-yearly-gbp-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    domestic: true,
    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: true,
    currency: 'gbp',
    basePrice: 66.50,
    priceType: 'steps',
    priceTiers: [{ to: 36, cost: 1.84722 }, { to: 199, cost: 2.00 }, { to: 399, cost: 1.8 }, { to: 1999, cost: 1.666667 }, { to: null, cost: 1.53333 }],
    qtyMin: 36,
    title: 'Spelling Shed - Yearly',
    subtitle: 'From 36 Pupils.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-gbp-jan23'],
    maybeSwitchTo: []
  },
  'edshed-metered-number-school-yearly-gbp-jan23': {
    id: 'edshed-metered-number-school-yearly-gbp-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    domestic: true,
    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'gbp',
    basePrice: 66.50,
    priceType: 'steps',
    priceTiers: [{ to: 36, cost: 1.84722 }, { to: 199, cost: 2.00 }, { to: 399, cost: 1.8 }, { to: 1999, cost: 1.666667 }, { to: null, cost: 1.53333 }],
    qtyMin: 36,
    title: 'MathShed - Yearly',
    subtitle: 'From 36 Pupils.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-gbp-jan23'],
    maybeSwitchTo: []
  },
  'edshed-metered-combined-school-yearly-gbp-jan23': {
    id: 'edshed-metered-combined-school-yearly-gbp-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    domestic: true,
    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'gbp',
    basePrice: 119.70,
    priceType: 'steps',
    priceTiers: [{ to: 36, cost: 3.325 }, { to: 199, cost: 3.60 }, { to: 399, cost: 3.24 }, { to: 1999, cost: 3.00 }, { to: null, cost: 2.76 }],
    qtyMin: 36,
    title: 'EdShed Online Combined - Yearly',
    subtitle: 'From 36 Pupils.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'edshed-metered-spelling-school-yearly-eur-jan23': {
    id: 'edshed-metered-spelling-school-yearly-eur-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    domestic: false,
    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: true,
    currency: 'eur',
    basePrice: 170.00,
    priceType: 'steps',
    priceTiers: [{ to: 36, cost: 4.72222 }, { to: 199, cost: 4.75 }, { to: 399, cost: 4.29 }, { to: 1999, cost: 4.00 }, { to: null, cost: 3.72 }],
    qtyMin: 36,
    title: 'Spelling Shed - Yearly',
    subtitle: 'From 36 Students.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-eur-jan23'],
    maybeSwitchTo: []
  },
  'edshed-metered-number-school-yearly-eur-jan23': {
    id: 'edshed-metered-number-school-yearly-eur-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    domestic: false,
    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'eur',
    basePrice: 170.00,
    priceType: 'steps',
    priceTiers: [{ to: 36, cost: 4.72222 }, { to: 199, cost: 4.75 }, { to: 399, cost: 4.29 }, { to: 1999, cost: 4.00 }, { to: null, cost: 3.72 }],
    qtyMin: 36,
    title: 'MathShed - Yearly',
    subtitle: 'From 36 Students.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-eur-jan23'],
    maybeSwitchTo: []
  },
  'edshed-metered-combined-school-yearly-eur-jan23': {
    id: 'edshed-metered-combined-school-yearly-eur-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    domestic: false,
    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'eur',
    basePrice: 306,
    priceType: 'steps',
    priceTiers: [{ to: 36, cost: 8.5 }, { to: 199, cost: 8.55 }, { to: 399, cost: 7.72 }, { to: 1999, cost: 7.20 }, { to: null, cost: 6.70 }],
    qtyMin: 36,
    title: 'EdShed Online Combined - Yearly',
    subtitle: 'From 36 Students.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'edshed-metered-spelling-school-yearly-aud-jan23': {
    id: 'edshed-metered-spelling-school-yearly-aud-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: true,
    currency: 'aud',
    basePrice: 210,
    priceType: 'steps',
    priceTiers: [{ to: 499, cost: 7.5 }, { to: 1999, cost: 6.95 }, { to: null, cost: 6.58 }],
    qtyMin: 28,
    title: 'Spelling Shed - Yearly',
    subtitle: 'From 28 Students.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-aud-jan23'],
    maybeSwitchTo: []
  },
  'edshed-metered-number-school-yearly-aud-jan23': {
    id: 'edshed-metered-number-school-yearly-aud-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'aud',
    basePrice: 210,
    priceType: 'steps',
    priceTiers: [{ to: 499, cost: 7.5 }, { to: 1999, cost: 6.95 }, { to: null, cost: 6.58 }],
    qtyMin: 28,
    title: 'MathShed - Yearly',
    subtitle: 'From 28 Students.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-aud-jan23'],
    maybeSwitchTo: []
  },
  'edshed-metered-combined-school-yearly-aud-jan23': {
    id: 'edshed-metered-combined-school-yearly-aud-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'aud',
    basePrice: 378,
    priceType: 'steps',
    priceTiers: [{ to: 499, cost: 13.5 }, { to: 1999, cost: 12.51 }, { to: null, cost: 11.84 }],
    qtyMin: 28,
    title: 'EdShed Online Combined - Yearly',
    subtitle: 'From 28 Students.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'edshed-metered-spelling-school-yearly-nzd-jan23': {
    id: 'edshed-metered-spelling-school-yearly-nzd-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: true,
    currency: 'nzd',
    basePrice: 224,
    priceType: 'steps',
    priceTiers: [{ to: 499, cost: 8 }, { to: 1999, cost: 7.41 }, { to: null, cost: 7.00 }],
    qtyMin: 28,
    title: 'Spelling Shed - Yearly',
    subtitle: 'From 28 Students.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-nzd-jan23'],
    maybeSwitchTo: []
  },
  'edshed-metered-number-school-yearly-nzd-jan23': {
    id: 'edshed-metered-number-school-yearly-nzd-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'nzd',
    basePrice: 224,
    priceType: 'steps',
    priceTiers: [{ to: 499, cost: 8 }, { to: 1999, cost: 7.41 }, { to: null, cost: 7.00 }],
    qtyMin: 28,
    title: 'MathShed - Yearly',
    subtitle: 'From 28 Students.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-nzd-jan23'],
    maybeSwitchTo: []
  },
  'edshed-metered-combined-school-yearly-nzd-jan23': {
    id: 'edshed-metered-combined-school-yearly-nzd-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'nzd',
    qtyMin: 28,
    basePrice: 403.2,
    priceType: 'steps',
    priceTiers: [{ to: 499, cost: 14.40 }, { to: 1999, cost: 13.39 }, { to: null, cost: 12.6 }],
    title: 'EdShed Online Combined - Yearly',
    subtitle: 'From 28 Students.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'edshed-metered-spelling-school-yearly-zar-jan23': {
    id: 'edshed-metered-spelling-school-yearly-zar-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    spelling: true,
    number: false,
    phonics: true,
    premium: true,
    school: true,
    currency: 'zar',
    basePrice: 3060.00,
    priceType: 'steps',
    priceTiers: [{ to: 36, cost: 85 }, { to: 199, cost: 85 }, { to: 399, cost: 77 }, { to: 1999, cost: 71 }, { to: null, cost: 65 }],
    qtyMin: 36,
    title: 'Spelling Shed and Phonics Shed - Yearly',
    subtitle: 'From 36 Students.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-zar-jan23'],
    maybeSwitchTo: []
  },
  'edshed-metered-number-school-yearly-zar-jan23': {
    id: 'edshed-metered-number-school-yearly-zar-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'zar',
    basePrice: 3060.00,
    priceType: 'steps',
    priceTiers: [{ to: 36, cost: 85 }, { to: 199, cost: 85 }, { to: 399, cost: 77 }, { to: 1999, cost: 71 }, { to: null, cost: 65 }],
    qtyMin: 36,
    title: 'Spelling Shed - Yearly',
    subtitle: 'From 36 Students.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-zar-jan23'],
    maybeSwitchTo: []
  },
  'edshed-metered-combined-school-yearly-zar-jan23': {
    id: 'edshed-metered-combined-school-yearly-zar-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    spelling: true,
    number: true,
    phonics: true,
    premium: true,
    school: true,
    currency: 'zar',
    basePrice: 5508.00,
    priceType: 'steps',
    priceTiers: [{ to: 36, cost: 153 }, { to: 199, cost: 153 }, { to: 399, cost: 139 }, { to: 1999, cost: 128 }, { to: null, cost: 117 }],
    qtyMin: 36,
    title: 'Spelling Shed, MathShed & Phonics Shed - Yearly',
    subtitle: 'From 36 Students.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  price_1MI9IWEwnA0RkkU7eZgLJHGp: {
    id: 'price_1MI9IWEwnA0RkkU7eZgLJHGp',
    accountRegion: 'US',
    group: 'edshed',
    period: 'year',
    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: true,
    currency: 'usd',
    basePrice: 140,
    priceType: 'steps',
    priceTiers: [{ to: 499, cost: 5.00 }, { to: 1999, cost: 4.63 }, { to: null, cost: 4.38 }],
    qtyMin: 28,
    title: 'Spelling Shed - Yearly',
    subtitle: 'From 28 Students.',
    test: false,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['price_1MI9TlEwnA0RkkU7OsAviLQM'],
    maybeSwitchTo: []
  },
  price_1MI9OvEwnA0RkkU7HNRslwAv: {
    id: 'price_1MI9OvEwnA0RkkU7HNRslwAv',
    accountRegion: 'US',
    group: 'edshed',
    period: 'year',
    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'usd',
    basePrice: 140,
    priceType: 'steps',
    priceTiers: [{ to: 499, cost: 5.00 }, { to: 1999, cost: 4.63 }, { to: null, cost: 4.38 }],
    qtyMin: 28,
    title: 'MathShed - Yearly',
    subtitle: 'From 28 Students.',
    test: false,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['price_1MI9TlEwnA0RkkU7OsAviLQM'],
    maybeSwitchTo: []
  },
  price_1MI9TlEwnA0RkkU7OsAviLQM: {
    id: 'price_1MI9TlEwnA0RkkU7OsAviLQM',
    accountRegion: 'US',
    group: 'edshed',
    period: 'year',
    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'usd',
    basePrice: 252,
    priceType: 'steps',
    priceTiers: [{ to: 499, cost: 9 }, { to: 1999, cost: 8.33 }, { to: null, cost: 7.88 }],
    qtyMin: 28,
    title: 'EdShed Online Combined - Yearly',
    subtitle: 'From 28 Students.',
    test: false,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'litshed-personal-jan23': {
    id: 'litshed-personal-jan23',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',
    domestic: true,
    school: false,
    currency: 'gbp',
    teachers: 1,
    basePrice: 24.9917,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - Personal',
    canSwitchTo: [],
    maybeSwitchTo: [
      {
        condition: ['org_type', '=', 'school'],
        plan: 'litshed-metered-school-gbp-jan23'
      }
    ]
  },
  'litshed-metered-school-gbp-jan23': {
    id: 'litshed-metered-school-gbp-jan23',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',
    domestic: true,
    school: true,
    currency: 'gbp',
    classes: 1,
    teachers: 4,
    qtyMin: 2,
    qtyMax: 40,
    basePrice: 47.50,
    priceType: 'steps',
    priceTiers: [{ to: null, cost: 23.75 }],
    title: 'Literacy Shed Plus - School',
    metered_entity: 'class',
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'litshed-personal-eur-jan23': {
    id: 'litshed-personal-eur-jan23',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',
    domestic: false,
    school: false,
    currency: 'eur',
    teachers: 1,
    basePrice: 33.99,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - Personal',
    canSwitchTo: [],
    maybeSwitchTo: [
      {
        condition: ['org_type', '=', 'school'],
        plan: 'litshed-metered-school-eur-jan23'
      }
    ]
  },
  'litshed-metered-school-eur-jan23': {
    id: 'litshed-metered-school-eur-jan23',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',
    domestic: false,
    school: true,
    currency: 'eur',
    classes: 1,
    teachers: 4,
    qtyMin: 2,
    qtyMax: 40,
    basePrice: 65.50,
    priceType: 'steps',
    priceTiers: [{ to: null, cost: 32.75 }],
    title: 'Literacy Shed Plus - School',
    metered_entity: 'class',
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'litshed-personal-aud-jan23': {
    id: 'litshed-personal-aud-jan23',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',
    school: false,
    currency: 'aud',
    teachers: 1,
    basePrice: 54.99,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - Personal',
    canSwitchTo: [],
    maybeSwitchTo: [
      {
        condition: ['org_type', '=', 'school'],
        plan: 'litshed-metered-school-aud-jan23'
      }
    ]
  },
  'litshed-metered-school-aud-jan23': {
    id: 'litshed-metered-school-aud-jan23',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',
    school: true,
    currency: 'aud',
    classes: 1,
    teachers: 4,
    qtyMin: 2,
    qtyMax: 40,
    basePrice: 102.00,
    priceType: 'steps',
    priceTiers: [{ to: null, cost: 51.00 }],
    title: 'Literacy Shed Plus - School',
    metered_entity: 'class',
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'litshed-personal-nzd-jan23': {
    id: 'litshed-personal-nzd-jan23',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',
    school: false,
    currency: 'nzd',
    teachers: 1,
    basePrice: 57.99,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - Personal',
    canSwitchTo: [],
    maybeSwitchTo: [
      {
        condition: ['org_type', '=', 'school'],
        plan: 'litshed-metered-school-nzd-jan23'
      }
    ]
  },
  'litshed-metered-school-nzd-jan23': {
    id: 'litshed-metered-school-nzd-jan23',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',
    school: true,
    currency: 'nzd',
    classes: 1,
    teachers: 4,
    qtyMin: 2,
    qtyMax: 40,
    basePrice: 111.50,
    priceType: 'steps',
    priceTiers: [{ to: null, cost: 55.75 }],
    title: 'Literacy Shed Plus - School',
    metered_entity: 'class',
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'litshed-personal-zar-jan23': {
    id: 'litshed-personal-zar-jan23',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',
    school: false,
    currency: 'zar',
    teachers: 1,
    basePrice: 600,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - Personal',
    canSwitchTo: [],
    maybeSwitchTo: [
      {
        condition: ['org_type', '=', 'school'],
        plan: 'litshed-metered-school-zar-jan23'
      }
    ]
  },
  'litshed-metered-school-zar-jan23': {
    id: 'litshed-metered-school-zar-jan23',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',
    school: true,
    currency: 'zar',
    classes: 1,
    teachers: 4,
    qtyMin: 2,
    qtyMax: 40,
    basePrice: 1140,
    priceType: 'steps',
    priceTiers: [{ to: null, cost: 570 }],
    title: 'Literacy Shed Plus - School',
    metered_entity: 'class',
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  price_1MI95rEwnA0RkkU7HtRwrYD7: {
    id: 'price_1MI95rEwnA0RkkU7HtRwrYD7',
    accountRegion: 'US',
    group: 'litshed',
    period: 'year',
    school: false,
    currency: 'usd',
    teachers: 1,
    basePrice: 39.99,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - Personal',
    canSwitchTo: [],
    maybeSwitchTo: [
      {
        condition: ['org_type', '=', 'school'],
        plan: 'price_1MI9C0EwnA0RkkU7IH6jiGlR'
      }
    ]
  },
  price_1MI9C0EwnA0RkkU7IH6jiGlR: {
    id: 'price_1MI9C0EwnA0RkkU7IH6jiGlR',
    accountRegion: 'US',
    group: 'litshed',
    period: 'year',
    school: true,
    currency: 'usd',
    classes: 1,
    teachers: 4,
    qtyMin: 2,
    qtyMax: 40,
    basePrice: 65,
    priceType: 'steps',
    priceTiers: [{ to: null, cost: 32.50 }],
    title: 'Literacy Shed Plus - School',
    metered_entity: 'class',
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'edshed-metered-spelling-home-yearly-gbp-jan23': {
    id: 'edshed-metered-spelling-home-yearly-gbp-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    domestic: true,
    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: false,
    currency: 'gbp',
    qtyMin: 5,
    basePrice: 32.50,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 6.50 }, { to: null, cost: 6.50 }],
    title: 'Spelling Shed - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-yearly-gbp-jan23'],
    maybeSwitchTo: []
  },
  'edshed-metered-spelling-home-monthly-gbp-jan23': {
    id: 'edshed-metered-spelling-home-monthly-gbp-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'month',
    domestic: true,
    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: false,
    currency: 'gbp',
    qtyMin: 5,
    basePrice: 5.40,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 1.08 }, { to: null, cost: 1.08 }],
    title: 'Spelling Shed - Home Monthly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-monthly-gbp-jan23'],
    maybeSwitchTo: [
      {
        plan: 'edshed-metered-spelling-school-yearly-gbp-jan23',
        condition: ['org_type', '=', 'business']
      },
      {
        plan: 'edshed-metered-combined-school-yearly-gbp-jan23',
        condition: ['org_type', '=', 'business']
      }
    ]
  },
  'edshed-metered-number-home-yearly-gbp-jan23': {
    id: 'edshed-metered-number-home-yearly-gbp-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    domestic: true,
    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'gbp',
    qtyMin: 5,
    basePrice: 32.50,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 6.50 }, { to: null, cost: 6.50 }],
    title: 'MathShed - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-yearly-gbp-jan23'],
    maybeSwitchTo: []
  },
  'edshed-metered-number-home-monthly-gbp-jan23': {
    id: 'edshed-metered-number-home-monthly-gbp-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'month',
    domestic: true,
    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'gbp',
    basePrice: 5.40,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 1.08 }, { to: null, cost: 1.08 }],
    qtyMin: 5,
    title: 'MathShed - Home Monthly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-monthly-gbp-jan23'],
    maybeSwitchTo: [
      {
        plan: 'edshed-metered-number-school-yearly-gbp-jan23',
        condition: ['org_type', '=', 'business']
      },
      {
        plan: 'edshed-metered-combined-school-yearly-gbp-jan23',
        condition: ['org_type', '=', 'business']
      }
    ]
  },
  'edshed-metered-combined-home-yearly-gbp-jan23': {
    id: 'edshed-metered-combined-home-yearly-gbp-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    domestic: true,
    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'gbp',
    basePrice: 58.50,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 11.7 }, { to: null, cost: 11.7 }],
    qtyMin: 5,
    title: 'EdShed Online Combined - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'edshed-metered-combined-home-monthly-gbp-jan23': {
    id: 'edshed-metered-combined-home-monthly-gbp-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'month',
    domestic: true,
    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'gbp',
    basePrice: 9.72,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 1.944 }, { to: null, cost: 1.94 }],
    qtyMin: 5,
    title: 'EdShed Online Combined - Home Monthly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: [
      {
        plan: 'edshed-metered-combined-school-yearly-gbp-jan23',
        condition: ['org_type', '=', 'business']
      }
    ]
  },
  'edshed-metered-spelling-home-yearly-eur-jan23': {
    id: 'edshed-metered-spelling-home-yearly-eur-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    domestic: false,
    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: false,
    currency: 'eur',
    basePrice: 74.99,
    qtyMin: 5,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 14.998 }, { to: null, cost: 15 }],
    title: 'Spelling Shed - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-yearly-eur-jan23'],
    maybeSwitchTo: []
  },
  'edshed-metered-number-home-yearly-eur-jan23': {
    id: 'edshed-metered-number-home-yearly-eur-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    domestic: false,
    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'eur',
    basePrice: 74.99,
    qtyMin: 5,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 14.998 }, { to: null, cost: 15 }],
    title: 'MathShed - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-yearly-eur-jan23'],
    maybeSwitchTo: []
  },
  'edshed-metered-combined-home-yearly-eur-jan23': {
    id: 'edshed-metered-combined-home-yearly-eur-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    domestic: false,
    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'eur',
    basePrice: 134.98,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 26.996 }, { to: null, cost: 27 }],
    qtyMin: 5,
    title: 'EdShed Online Combined - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'edshed-metered-spelling-home-yearly-aud-jan23': {
    id: 'edshed-metered-spelling-home-yearly-aud-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: false,
    currency: 'aud',
    basePrice: 74.99,
    qtyMin: 5,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 14.998 }, { to: null, cost: 15 }],
    title: 'Spelling Shed - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-yearly-aud-jan23'],
    maybeSwitchTo: []
  },
  'edshed-metered-number-home-yearly-aud-jan23': {
    id: 'edshed-metered-number-home-yearly-aud-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'aud',
    basePrice: 74.99,
    qtyMin: 5,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 14.998 }, { to: null, cost: 15 }],
    title: 'MathShed - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-yearly-aud-jan23'],
    maybeSwitchTo: []
  },
  'edshed-metered-combined-home-yearly-aud-jan23': {
    id: 'edshed-metered-combined-home-yearly-aud-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'aud',
    basePrice: 134.98,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 26.996 }, { to: null, cost: 27 }],
    qtyMin: 5,
    title: 'EdShed Online Combined - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'edshed-metered-spelling-home-yearly-nzd-jan23': {
    id: 'edshed-metered-spelling-home-yearly-nzd-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: false,
    currency: 'nzd',
    basePrice: 74.99,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 14.998 }, { to: null, cost: 15 }],
    qtyMin: 5,
    title: 'Spelling Shed - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-yearly-nzd-jan23'],
    maybeSwitchTo: []
  },
  'edshed-metered-number-home-yearly-nzd-jan23': {
    id: 'edshed-metered-number-home-yearly-nzd-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'nzd',
    basePrice: 74.99,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 14.998 }, { to: null, cost: 15 }],
    qtyMin: 5,
    title: 'MathShed - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-yearly-nzd'],
    maybeSwitchTo: []
  },
  'edshed-metered-combined-home-yearly-nzd-jan23': {
    id: 'edshed-metered-combined-home-yearly-nzd-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'nzd',
    basePrice: 134.98,
    qtyMin: 5,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 26.996 }, { to: null, cost: 27 }],
    title: 'EdShed Online Combined - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'edshed-metered-spelling-home-yearly-zar-jan23': {
    id: 'edshed-metered-spelling-home-yearly-zar-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    spelling: true,
    number: false,
    phonics: true,
    premium: true,
    school: false,
    currency: 'zar',
    qtyMin: 5,
    basePrice: 1250,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 250 }, { to: null, cost: 250 }],
    title: 'Spelling Shed & Phonics Shed - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-yearly-zar-jan23'],
    maybeSwitchTo: []
  },
  'edshed-metered-number-home-yearly-zar-jan23': {
    id: 'edshed-metered-number-home-yearly-zar-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'zar',
    qtyMin: 5,
    basePrice: 1250,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 250 }, { to: null, cost: 250 }],
    title: 'MathShed - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-yearly-zar-jan23'],
    maybeSwitchTo: []
  },
  'edshed-metered-combined-home-yearly-zar-jan23': {
    id: 'edshed-metered-combined-home-yearly-zar-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    spelling: true,
    number: true,
    phonics: true,
    premium: true,
    school: false,
    currency: 'zar',
    qtyMin: 5,
    basePrice: 2250,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 450 }, { to: null, cost: 450 }],
    title: 'Spelling Shed, MathShed & Phonics Shed - Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  price_1MI9a6EwnA0RkkU7dAz9lfDP: {
    id: 'price_1MI9a6EwnA0RkkU7dAz9lfDP',
    accountRegion: 'US',
    group: 'edshed',
    period: 'year',
    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: false,
    currency: 'usd',
    basePrice: 62.50,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 12.5 }, { to: null, cost: 12.5 }],
    qtyMin: 5,
    title: 'Spelling Shed - Home Yearly',
    subtitle: 'Up to 5 Children',
    test: false,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['price_1MI9gMEwnA0RkkU7ZSkSC50Y'],
    maybeSwitchTo: []
  },
  price_1MI9cUEwnA0RkkU7WOlX2GBe: {
    id: 'price_1MI9cUEwnA0RkkU7WOlX2GBe',
    accountRegion: 'US',
    group: 'edshed',
    period: 'year',
    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'usd',
    basePrice: 62.50,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 12.5 }, { to: null, cost: 12.5 }],
    qtyMin: 5,
    title: 'MathShed - Home Yearly',
    subtitle: 'Up to 5 Children',
    test: false,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['price_1MI9gMEwnA0RkkU7ZSkSC50Y'],
    maybeSwitchTo: []
  },
  price_1MI9gMEwnA0RkkU7ZSkSC50Y: {
    id: 'price_1MI9gMEwnA0RkkU7ZSkSC50Y',
    accountRegion: 'US',
    group: 'edshed',
    period: 'year',
    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'usd',
    basePrice: 112.50,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 22.50 }, { to: null, cost: 22.50 }],
    qtyMin: 5,
    title: 'EdShed Online Combined - Home Yearly',
    subtitle: 'Up to 5 Children',
    test: false,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'edshed-metered-spelling-school-yearly-gbp-intl-feb23': {
    id: 'edshed-metered-spelling-school-yearly-gbp-intl-feb23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    domestic: false,
    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: true,
    currency: 'gbp',
    basePrice: 150.00,
    priceType: 'steps',
    priceTiers: [{ to: 36, cost: 4.166667 }, { to: 199, cost: 4.23 }, { to: 399, cost: 3.82 }, { to: 1999, cost: 3.56 }, { to: null, cost: 3.31 }],
    qtyMin: 36,
    title: 'Spelling Shed - Yearly',
    subtitle: 'From 36 Pupils.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-gbp-intl-jan23'],
    maybeSwitchTo: []
  },
  'edshed-metered-number-school-yearly-gbp-intl-jan23': {
    id: 'edshed-metered-number-school-yearly-gbp-intl-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    domestic: false,
    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'gbp',
    basePrice: 150,
    priceType: 'steps',
    priceTiers: [{ to: 36, cost: 4.166667 }, { to: 199, cost: 4.23 }, { to: 399, cost: 3.82 }, { to: 1999, cost: 3.56 }, { to: null, cost: 3.31 }],
    qtyMin: 36,
    title: 'MathShed - Yearly',
    subtitle: 'From 36 Pupils.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-gbp-intl-jan23'],
    maybeSwitchTo: []
  },
  'edshed-metered-combined-school-yearly-gbp-intl-jan23': {
    id: 'edshed-metered-combined-school-yearly-gbp-intl-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    domestic: false,
    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'gbp',
    basePrice: 270,
    priceType: 'steps',
    priceTiers: [{ to: 36, cost: 7.50 }, { to: 199, cost: 7.61 }, { to: 399, cost: 6.88 }, { to: 1999, cost: 6.41 }, { to: null, cost: 5.96 }],
    qtyMin: 36,
    title: 'EdShed Online Combined - Yearly',
    subtitle: 'From 36 Pupils.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'edshed-metered-spelling-school-yearly-eur-dom-jan23': {
    id: 'edshed-metered-spelling-school-yearly-eur-dom-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    domestic: true,
    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: true,
    currency: 'eur',
    basePrice: 75.00,
    priceType: 'steps',
    priceTiers: [{ to: 36, cost: 2.083333 }, { to: 199, cost: 2.26 }, { to: 399, cost: 2.03 }, { to: 1999, cost: 1.88 }, { to: null, cost: 1.73 }],
    qtyMin: 36,
    title: 'Spelling Shed - Yearly',
    subtitle: 'From 36 Students.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-eur-dom-jan23'],
    maybeSwitchTo: []
  },
  'edshed-metered-number-school-yearly-eur-dom-jan23': {
    id: 'edshed-metered-number-school-yearly-eur-dom-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    domestic: true,
    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'eur',
    basePrice: 75.00,
    priceType: 'steps',
    priceTiers: [{ to: 36, cost: 2.083333 }, { to: 199, cost: 2.26 }, { to: 399, cost: 2.03 }, { to: 1999, cost: 1.88 }, { to: null, cost: 1.73 }],
    qtyMin: 36,
    title: 'MathShed - Yearly',
    subtitle: 'From 36 Students.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-eur-dom-jan23'],
    maybeSwitchTo: []
  },
  'edshed-metered-combined-school-yearly-eur-dom-jan23': {
    id: 'edshed-metered-combined-school-yearly-eur-dom-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    domestic: true,
    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'eur',
    basePrice: 135,
    priceType: 'steps',
    priceTiers: [{ to: 36, cost: 3.75 }, { to: 199, cost: 4.07 }, { to: 399, cost: 3.65 }, { to: 1999, cost: 3.38 }, { to: null, cost: 3.11 }],
    qtyMin: 36,
    title: 'EdShed Online Combined - Yearly',
    subtitle: 'From 36 Students.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'edshed-metered-spelling-home-yearly-gbp-intl-jan23': {
    id: 'edshed-metered-spelling-home-yearly-gbp-intl-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    domestic: false,
    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: false,
    currency: 'gbp',
    basePrice: 66.50,
    qtyMin: 5,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 13.30 }, { to: null, cost: 13.30 }],
    title: 'Spelling Shed - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-yearly-gbp-intl-jan23'],
    maybeSwitchTo: []
  },
  'edshed-metered-number-home-yearly-gbp-intl-jan23': {
    id: 'edshed-metered-number-home-yearly-gbp-intl-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    domestic: false,
    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'gbp',
    basePrice: 66.50,
    qtyMin: 5,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 13.30 }, { to: null, cost: 13.30 }],
    title: 'MathShed - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-yearly-gbp-intl-jan23'],
    maybeSwitchTo: []
  },
  'edshed-metered-combined-home-yearly-gbp-intl-jan23': {
    id: 'edshed-metered-combined-home-yearly-gbp-intl-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    domestic: false,
    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'gbp',
    basePrice: 119.70,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 23.94 }, { to: null, cost: 23.94 }],
    qtyMin: 5,
    title: 'EdShed Online Combined - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'edshed-metered-spelling-home-yearly-eur-dom-jan23': {
    id: 'edshed-metered-spelling-home-yearly-eur-dom-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    domestic: true,
    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: false,
    currency: 'eur',
    qtyMin: 5,
    basePrice: 36.50,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 7.30 }, { to: null, cost: 7.30 }],
    title: 'Spelling Shed - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-yearly-eur-dom-jan23'],
    maybeSwitchTo: []
  },
  'edshed-metered-number-home-yearly-eur-dom-jan23': {
    id: 'edshed-metered-number-home-yearly-eur-dom-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    domestic: true,
    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'eur',
    qtyMin: 5,
    basePrice: 36.50,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 7.30 }, { to: null, cost: 7.30 }],
    title: 'MathShed - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-yearly-eur-dom-jan23'],
    maybeSwitchTo: []
  },
  'edshed-metered-combined-home-yearly-eur-dom-jan23': {
    id: 'edshed-metered-combined-home-yearly-eur-dom-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    domestic: true,
    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'eur',
    qtyMin: 5,
    basePrice: 65.70,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 13.14 }, { to: null, cost: 13.14 }],
    title: 'EdShed Online Combined - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'litshed-personal-gbp-intl-jan23': {
    id: 'litshed-personal-gbp-intl-jan23',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',
    domestic: false,
    school: false,
    currency: 'gbp',
    teachers: 1,
    basePrice: 30.25,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - Personal',
    canSwitchTo: [],
    maybeSwitchTo: [
      {
        condition: ['org_type', '=', 'school'],
        plan: 'litshed-metered-school-gbp-intl-feb23'
      }
    ]
  },
  'litshed-metered-school-gbp-intl-jan23': {
    id: 'litshed-metered-school-gbp-intl-jan23',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',
    domestic: false,
    school: true,
    currency: 'gbp',
    classes: 1,
    teachers: 4,
    qtyMin: 2,
    qtyMax: 40,
    basePrice: 58.50,
    priceType: 'steps',
    priceTiers: [{ to: null, cost: 29.25 }],
    title: 'Literacy Shed Plus - School',
    metered_entity: 'class',
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'litshed-metered-school-gbp-intl-feb23': {
    id: 'litshed-metered-school-gbp-intl-feb23',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',
    domestic: false,
    school: true,
    currency: 'gbp',
    classes: 1,
    teachers: 4,
    qtyMin: 2,
    qtyMax: 40,
    basePrice: 58.50,
    priceType: 'steps',
    priceTiers: [{ to: null, cost: 29.25 }],
    title: 'Literacy Shed Plus - School',
    metered_entity: 'class',
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'litshed-personal-eur-dom-jan23': {
    id: 'litshed-personal-eur-dom-jan23',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',
    domestic: true,
    school: false,
    currency: 'eur',
    teachers: 1,
    basePrice: 28.00,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - Personal',
    canSwitchTo: [],
    maybeSwitchTo: [
      {
        condition: ['org_type', '=', 'school'],
        plan: 'litshed-metered-school-eur-dom-jan23'
      }
    ]
  },
  'litshed-metered-school-eur-dom-jan23': {
    id: 'litshed-metered-school-eur-dom-jan23',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',
    domestic: true,
    school: true,
    currency: 'eur',
    classes: 1,
    teachers: 4,
    qtyMin: 2,
    qtyMax: 40,
    basePrice: 53.50,
    priceType: 'steps',
    priceTiers: [{ to: null, cost: 26.75 }],
    title: 'Literacy Shed Plus - School',
    metered_entity: 'class',
    canSwitchTo: [],
    maybeSwitchTo: []
  }
}

export const prodSubPlans: SubscriptionPlan[] = Object.values(subObj) as SubscriptionPlan[]
