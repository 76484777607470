import { SubscriptionPlan, SubscriptionPlanId } from '..'

type SubDef = { [K in SubscriptionPlanId]: (SubscriptionPlan & { id: K, test: true }) }

const subObj: Partial<SubDef> = {
  'test-spelling-personal':
  {
    id: 'test-spelling-personal',
    accountRegion: 'GB',
    period: 'year',

    group: 'edshed',
    school: false,
    premium: true,
    spelling: true,
    number: false,
    phonics: false,
    currency: 'gbp',
    basePrice: 30,
    priceType: 'fixed',
    metered_entity: null,
    pupils: 36,
    test: true,
    title: 'Spelling Shed Personal',
    canSwitchTo: ['edshed-metered-spelling-school-yearly-gbp', 'edshed-metered-combined-school-yearly-gbp'],
    maybeSwitchTo: []
  },
  'both-personal': {
    id: 'both-personal',
    accountRegion: 'GB',
    period: 'year',

    group: 'edshed',
    school: false,
    premium: true,
    spelling: true,
    number: true,
    phonics: false,
    currency: 'gbp',
    basePrice: 50,
    priceType: 'fixed',
    metered_entity: null,
    pupils: 36,
    title: 'Spelling Shed + MathShed Personal',
    canSwitchTo: ['edshed-metered-combined-school-yearly-gbp'],
    maybeSwitchTo: [],
    test: true
  },
  'litshed-school-8': {
    id: 'litshed-school-8',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'gbp',
    classes: 8,
    teachers: 32,
    basePrice: 156.00,
    priceType: 'fixed',
    metered_entity: null,
    test: true,
    title: 'Literacy Shed Plus - School (8 Classes)',
    canSwitchTo: ['litshed-metered-school-gbp'],
    maybeSwitchTo: []
  },
  'litshed-personal-mar22': {
    id: 'litshed-personal-mar22',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: false,
    currency: 'gbp',
    teachers: 1,
    basePrice: 22.917,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - Personal',
    canSwitchTo: [],
    test: true,
    maybeSwitchTo: [
      {
        condition: ['org_type', '=', 'school'],
        plan: 'litshed-metered-school-gbp-mar22'
      }
    ]
  },
  'litshed-personal': {
    id: 'litshed-personal',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: false,
    currency: 'gbp',
    teachers: 1,
    basePrice: 20.825,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - Personal',
    test: true,
    canSwitchTo: [],
    maybeSwitchTo: [
      {
        plan: 'litshed-metered-school-gbp',
        condition: ['org_type', '=', 'business']
      },
      {
        plan: 'litshed-metered-school-gbp',
        condition: ['org_type', '=', 'school']
      }
    ]
  },
  'litshed-personal-usd': {
    id: 'litshed-personal-usd',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: false,
    currency: 'usd',
    teachers: 1,
    basePrice: 34.99,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - Personal',
    test: true,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'litshed-personal-zar': {
    id: 'litshed-personal-zar',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: false,
    currency: 'zar',
    teachers: 1,
    basePrice: 460,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - Personal',
    test: true,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  price_1Gu4DzEwnA0RkkU7Q78whUIE: {
    id: 'price_1Gu4DzEwnA0RkkU7Q78whUIE',
    accountRegion: 'US',
    group: 'litshed',
    period: 'year',

    school: false,
    currency: 'usd',
    teachers: 1,
    basePrice: 34.99,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - Personal',
    test: true,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'litshed-personal-eur': {
    id: 'litshed-personal-eur',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: false,
    currency: 'eur',
    teachers: 1,
    basePrice: 29.99,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - Personal',
    test: true,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'litshed-personal-aud': {
    id: 'litshed-personal-aud',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: false,
    currency: 'aud',
    teachers: 1,
    basePrice: 49.99,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - Personal',
    test: true,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'litshed-personal-nzd': {
    id: 'litshed-personal-nzd',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: false,
    currency: 'nzd',
    teachers: 1,
    basePrice: 49.99,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - Personal',
    test: true,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'litshed-metered-school-gbp': {
    id: 'litshed-metered-school-gbp',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'gbp',
    classes: 1,
    teachers: 4,
    qtyMin: 2,
    qtyMax: 40,
    basePrice: 39.00,
    priceType: 'steps',
    priceTiers: [{ to: null, cost: 19.50 }],
    title: 'Literacy Shed Plus - School',
    test: true,
    metered_entity: 'class',
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'litshed-metered-school-gbp-mar22': {
    id: 'litshed-metered-school-gbp-mar22',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'gbp',
    classes: 1,
    teachers: 4,
    qtyMin: 2,
    qtyMax: 40,
    basePrice: 43.00,
    priceType: 'steps',
    priceTiers: [{ to: null, cost: 21.50 }],
    title: 'Literacy Shed Plus - School',
    test: true,
    metered_entity: 'class',
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'litshed-metered-school-usd': {
    id: 'litshed-metered-school-usd',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'usd',
    classes: 1,
    teachers: 4,
    qtyMin: 2,
    qtyMax: 40,
    basePrice: 59.00,
    priceType: 'steps',
    priceTiers: [{ to: null, cost: 29.50 }],
    title: 'Literacy Shed Plus - School',
    metered_entity: 'class',
    canSwitchTo: [],
    test: true,
    maybeSwitchTo: []
  },
  'litshed-metered-school-zar': {
    id: 'litshed-metered-school-zar',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'zar',
    classes: 1,
    teachers: 4,
    qtyMin: 2,
    qtyMax: 40,
    basePrice: 860,
    priceType: 'steps',
    priceTiers: [{ to: null, cost: 430 }],
    title: 'Literacy Shed Plus - School',
    test: true,
    metered_entity: 'class',
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  price_1Gu4FLEwnA0RkkU7KnWuPopi: {
    id: 'price_1Gu4FLEwnA0RkkU7KnWuPopi',
    accountRegion: 'US',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'usd',
    classes: 1,
    teachers: 4,
    qtyMin: 2,
    qtyMax: 40,
    basePrice: 59.00,
    priceType: 'steps',
    priceTiers: [{ to: null, cost: 29.50 }],
    title: 'Literacy Shed Plus - School',
    test: true,
    metered_entity: 'class',
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'litshed-metered-school-aud': {
    id: 'litshed-metered-school-aud',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'aud',
    classes: 1,
    teachers: 4,
    qtyMin: 2,
    qtyMax: 40,
    basePrice: 80.00,
    priceType: 'steps',
    priceTiers: [{ to: null, cost: 40.00 }],
    title: 'Literacy Shed Plus - School',
    test: true,
    metered_entity: 'class',
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'litshed-metered-school-nzd': {
    id: 'litshed-metered-school-nzd',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',

    school: true,
    currency: 'nzd',
    classes: 1,
    teachers: 4,
    qtyMin: 2,
    qtyMax: 40,
    basePrice: 80.00,
    priceType: 'steps',
    priceTiers: [{ to: null, cost: 40.00 }],
    title: 'Literacy Shed Plus - School',
    test: true,
    metered_entity: 'class',
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  // this one was wrong in Stripe test account but could not be redefined - never use it
  'edshed-metered-spelling-home-yearly-gbp': {
    id: 'edshed-metered-spelling-home-yearly-gbp',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: false,
    currency: 'gbp',
    qtyMin: 5,
    basePrice: 24.99,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 4.998 }, { to: null, cost: 5.00 }],
    title: 'Spelling Shed - Home Yearly',
    subtitle: 'Up to 5 Children',
    test: true,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['test-edshed-metered-combined-home-yearly-gbp'],
    maybeSwitchTo: []
  },
  'test-edshed-metered-spelling-home-yearly-gbp': {
    id: 'test-edshed-metered-spelling-home-yearly-gbp',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: false,
    currency: 'gbp',
    qtyMin: 5,
    basePrice: 24.99,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 4.998 }, { to: null, cost: 5.00 }],
    title: 'Spelling Shed - Home Yearly',
    subtitle: 'Up to 5 Children',
    test: true,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['test-edshed-metered-combined-home-yearly-gbp'],
    maybeSwitchTo: []
  },
  'edshed-metered-spelling-home-monthly-gbp': {
    id: 'edshed-metered-spelling-home-monthly-gbp',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'month',

    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: false,
    currency: 'gbp',
    qtyMin: 5,
    basePrice: 4.16,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 0.832 }, { to: null, cost: 0.83 }],
    title: 'Spelling Shed - Home Monthly',
    subtitle: 'Up to 5 Children',
    test: true,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-monthly-gbp'],
    maybeSwitchTo: [
      {
        plan: 'edshed-metered-spelling-school-yearly-gbp',
        condition: ['org_type', '=', 'business']
      },
      {
        plan: 'edshed-metered-combined-school-yearly-gbp',
        condition: ['org_type', '=', 'business']
      }
    ]
  },
  'edshed-metered-number-home-yearly-gbp': {
    id: 'edshed-metered-number-home-yearly-gbp',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'gbp',
    qtyMin: 5,
    basePrice: 24.99,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 4.998 }, { to: null, cost: 5.00 }],
    title: 'MathShed - Home Yearly',
    subtitle: 'Up to 5 Children',
    test: true,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['test-edshed-metered-combined-home-yearly-gbp'],
    maybeSwitchTo: []
  },
  // this one was wrong in Stripe test account but could not be redefined - never use it
  'edshed-metered-number-home-monthly-gbp':
  {
    id: 'edshed-metered-number-home-monthly-gbp',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'month',

    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'gbp',
    basePrice: 4.16,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 0.832 }, { to: null, cost: 0.83 }],
    qtyMin: 5,
    title: 'MathShed - Home Monthly',
    subtitle: 'Up to 5 Children',
    test: true,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-monthly-gbp'],
    maybeSwitchTo: [
      {
        plan: 'edshed-metered-number-school-yearly-gbp',
        condition: ['org_type', '=', 'business']
      },
      {
        plan: 'edshed-metered-combined-school-yearly-gbp',
        condition: ['org_type', '=', 'business']
      }
    ]
  },
  'test-edshed-metered-number-home-monthly-gbp': {
    id: 'test-edshed-metered-number-home-monthly-gbp',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'month',

    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'gbp',
    basePrice: 4.16,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 0.832 }, { to: null, cost: 0.83 }],
    qtyMin: 5,
    title: 'MathShed - Home Monthly',
    subtitle: 'Up to 5 Children',
    test: true,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-monthly-gbp'],
    maybeSwitchTo: [
      {
        plan: 'edshed-metered-number-school-yearly-gbp',
        condition: ['org_type', '=', 'business']
      },
      {
        plan: 'edshed-metered-combined-school-yearly-gbp',
        condition: ['org_type', '=', 'business']
      }
    ]
  },
  'test-edshed-metered-combined-home-yearly-gbp': {
    id: 'test-edshed-metered-combined-home-yearly-gbp',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'gbp',
    basePrice: 42.49,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 8.498 }, { to: null, cost: 8.50 }],
    qtyMin: 5,
    title: 'EdShed Online Combined - Home Yearly',
    subtitle: 'Up to 5 Children',
    test: true,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  // this one was wrong in Stripe test account but could not be redefined - never use it
  'edshed-metered-combined-home-yearly-gbp':
  {
    id: 'edshed-metered-combined-home-yearly-gbp',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'gbp',
    basePrice: 42.49,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 8.498 }, { to: null, cost: 8.50 }],
    qtyMin: 5,
    title: 'EdShed Online Combined - Home Yearly',
    subtitle: 'Up to 5 Children',
    test: true,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'edshed-metered-combined-home-monthly-gbp': {
    id: 'edshed-metered-combined-home-monthly-gbp',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'month',

    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'gbp',
    basePrice: 7.07,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 1.414 }, { to: null, cost: 1.42 }],
    qtyMin: 5,
    title: 'EdShed Online Combined - Home Monthly',
    subtitle: 'Up to 5 Children',
    test: true,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: [
      {
        plan: 'edshed-metered-combined-school-yearly-gbp',
        condition: ['org_type', '=', 'business']
      }
    ]
  },
  'edshed-metered-spelling-home-yearly-usd': {
    id: 'edshed-metered-spelling-home-yearly-usd',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: false,
    currency: 'usd',
    basePrice: 24.95,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 4.99 }, { to: null, cost: 4.99 }],
    qtyMin: 5,
    title: 'Spelling Shed - Home Yearly',
    subtitle: 'Up to 5 Children',
    test: true,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-yearly-usd'],
    maybeSwitchTo: []
  },
  'edshed-metered-number-home-yearly-usd': {
    id: 'edshed-metered-number-home-yearly-usd',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'usd',
    basePrice: 24.95,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 4.99 }, { to: null, cost: 4.99 }],
    qtyMin: 5,
    title: 'MathShed - Home Yearly',
    subtitle: 'Up to 5 Children',
    test: true,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-yearly-usd'],
    maybeSwitchTo: []
  },
  'edshed-metered-combined-home-yearly-usd': {
    id: 'edshed-metered-combined-home-yearly-usd',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'usd',
    basePrice: 42.42, // + VAT = 29.99
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 8.484 }, { to: null, cost: 8.49 }],
    qtyMin: 5,
    title: 'EdShed Online Combined - Home Yearly',
    subtitle: 'Up to 5 Children',
    test: true,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'edshed-metered-spelling-home-yearly-eur': {
    id: 'edshed-metered-spelling-home-yearly-eur',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: false,
    currency: 'eur',
    basePrice: 29.99,
    qtyMin: 5,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 5.998 }, { to: null, cost: 6.00 }],
    title: 'Spelling Shed - Home Yearly',
    subtitle: 'Up to 5 Children',
    test: true,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-yearly-eur'],
    maybeSwitchTo: []
  },
  'edshed-metered-number-home-yearly-eur': {
    id: 'edshed-metered-number-home-yearly-eur',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'eur',
    basePrice: 29.99,
    qtyMin: 5,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 5.998 }, { to: null, cost: 6.00 }],
    title: 'MathShed - Home Yearly',
    subtitle: 'Up to 5 Children',
    test: true,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-yearly-eur'],
    maybeSwitchTo: []
  },
  'edshed-metered-combined-home-yearly-eur': {
    id: 'edshed-metered-combined-home-yearly-eur',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'eur',
    basePrice: 50.98,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 10.196 }, { to: null, cost: 10.20 }],
    qtyMin: 5,
    title: 'EdShed Online Combined - Home Yearly',
    subtitle: 'Up to 5 Children',
    test: true,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'edshed-metered-spelling-home-yearly-aud': {
    id: 'edshed-metered-spelling-home-yearly-aud',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: false,
    currency: 'aud',
    basePrice: 29.99,
    qtyMin: 5,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 5.998 }, { to: null, cost: 6.00 }],
    title: 'Spelling Shed - Home Yearly',
    subtitle: 'Up to 5 Children',
    test: true,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-yearly-aud'],
    maybeSwitchTo: []
  },
  'edshed-metered-number-home-yearly-aud': {
    id: 'edshed-metered-number-home-yearly-aud',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'aud',
    basePrice: 29.99,
    qtyMin: 5,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 5.998 }, { to: null, cost: 6.00 }],
    title: 'MathShed - Home Yearly',
    subtitle: 'Up to 5 Children',
    test: true,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-yearly-aud'],
    maybeSwitchTo: []
  },
  'edshed-metered-combined-home-yearly-aud': {
    id: 'edshed-metered-combined-home-yearly-aud',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'aud',
    basePrice: 50.98,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 10.196 }, { to: null, cost: 10.20 }],
    qtyMin: 5,
    title: 'EdShed Online Combined - Home Yearly',
    subtitle: 'Up to 5 Children',
    test: true,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'edshed-metered-spelling-home-yearly-nzd': {
    id: 'edshed-metered-spelling-home-yearly-nzd',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: false,
    currency: 'nzd',
    basePrice: 29.99,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 5.998 }, { to: null, cost: 6.00 }],
    qtyMin: 5,
    title: 'Spelling Shed - Home Yearly',
    subtitle: 'Up to 5 Children',
    test: true,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-yearly-nzd'],
    maybeSwitchTo: []
  },
  'edshed-metered-number-home-yearly-nzd': {
    id: 'edshed-metered-number-home-yearly-nzd',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'nzd',
    basePrice: 29.99,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 5.998 }, { to: null, cost: 6.00 }],
    qtyMin: 5,
    title: 'MathShed - Home Yearly',
    subtitle: 'Up to 5 Children',
    test: true,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-yearly-nzd'],
    maybeSwitchTo: []
  },
  'edshed-metered-combined-home-yearly-nzd': {
    id: 'edshed-metered-combined-home-yearly-nzd',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'nzd',
    basePrice: 50.98,
    qtyMin: 5,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 10.196 }, { to: null, cost: 10.20 }],
    title: 'EdShed Online Combined - Home Yearly',
    subtitle: 'Up to 5 Children',
    test: true,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'edshed-metered-spelling-school-yearly-gbp': {
    id: 'edshed-metered-spelling-school-yearly-gbp',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: true,
    currency: 'gbp',
    basePrice: 30.00,
    priceType: 'steps',
    priceTiers: [{ to: 36, cost: 0.83333333 }, { to: 199, cost: 0.83 }, { to: 399, cost: 0.75 }, { to: 1999, cost: 0.70 }, { to: null, cost: 0.65 }],
    qtyMin: 36,
    title: 'Spelling Shed - Yearly',
    subtitle: 'From 36 Pupils.',
    test: true,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-gbp'],
    maybeSwitchTo: []
  },
  'edshed-metered-spelling-school-yearly-gbp-mar22': {
    id: 'edshed-metered-spelling-school-yearly-gbp-mar22',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: true,
    currency: 'gbp',
    basePrice: 49.99,
    priceType: 'steps',
    priceTiers: [{ to: 36, cost: 1.388657 }, { to: 199, cost: 1.50 }, { to: 399, cost: 1.35 }, { to: 1999, cost: 1.25 }, { to: null, cost: 1.15 }],
    qtyMin: 36,
    title: 'Spelling Shed - Yearly',
    subtitle: 'From 36 Pupils.',
    test: true,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-gbp-mar22'],
    maybeSwitchTo: []
  },
  'edshed-metered-number-school-yearly-gbp': {
    id: 'edshed-metered-number-school-yearly-gbp',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'gbp',
    basePrice: 30.00,
    priceType: 'steps',
    priceTiers: [{ to: 36, cost: 0.83333333 }, { to: 199, cost: 0.83 }, { to: 399, cost: 0.75 }, { to: 1999, cost: 0.70 }, { to: null, cost: 0.65 }],
    qtyMin: 36,
    title: 'MathShed - Yearly',
    subtitle: 'From 36 Pupils.',
    test: true,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-gbp'],
    maybeSwitchTo: []
  },
  'edshed-metered-number-school-yearly-gbp-mar22': {
    id: 'edshed-metered-number-school-yearly-gbp-mar22',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'gbp',
    basePrice: 49.99,
    priceType: 'steps',
    priceTiers: [{ to: 36, cost: 1.388657 }, { to: 199, cost: 1.50 }, { to: 399, cost: 1.35 }, { to: 1999, cost: 1.25 }, { to: null, cost: 1.15 }],
    qtyMin: 36,
    title: 'MathShed - Yearly',
    subtitle: 'From 36 Pupils.',
    test: true,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-gbp-mar22'],
    maybeSwitchTo: []
  },
  'edshed-metered-combined-school-yearly-gbp': {
    id: 'edshed-metered-combined-school-yearly-gbp',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'gbp',
    basePrice: 50.00,
    priceType: 'steps',
    priceTiers: [{ to: 36, cost: 1.38888889 }, { to: 199, cost: 1.38 }, { to: 399, cost: 1.25 }, { to: 1999, cost: 1.17 }, { to: null, cost: 1.08 }],
    qtyMin: 36,
    title: 'EdShed Online Combined - Yearly',
    subtitle: 'From 36 Pupils.',
    test: true,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'edshed-metered-combined-school-yearly-gbp-mar22': {
    id: 'edshed-metered-combined-school-yearly-gbp-mar22',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'gbp',
    basePrice: 83.325,
    priceType: 'steps',
    priceTiers: [{ to: 36, cost: 2.36083 }, { to: 199, cost: 2.50 }, { to: 399, cost: 2.25 }, { to: 1999, cost: 2.11 }, { to: null, cost: 1.95 }],
    qtyMin: 36,
    title: 'EdShed Online Combined - Yearly',
    subtitle: 'From 36 Pupils.',
    test: true,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'edshed-metered-spelling-school-yearly-usd': {
    id: 'edshed-metered-spelling-school-yearly-usd',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: true,
    currency: 'usd',
    basePrice: 56.00,
    priceType: 'steps',
    priceTiers: [{ to: 499, cost: 2.00 }, { to: 1999, cost: 1.85 }, { to: null, cost: 1.75 }],
    qtyMin: 28,
    title: 'Spelling Shed - Yearly',
    subtitle: 'From 28 Students.',
    test: true,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-usd'],
    maybeSwitchTo: []
  },
  'edshed-metered-number-school-yearly-usd': {
    id: 'edshed-metered-number-school-yearly-usd',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'usd',
    basePrice: 56.00,
    priceType: 'steps',
    priceTiers: [{ to: 499, cost: 2.00 }, { to: 1999, cost: 1.85 }, { to: null, cost: 1.75 }],
    qtyMin: 28,
    title: 'MathShed - Yearly',
    subtitle: 'From 28 Students.',
    test: true,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-usd'],
    maybeSwitchTo: []
  },
  'edshed-metered-combined-school-yearly-usd': {
    id: 'edshed-metered-combined-school-yearly-usd',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'usd',
    basePrice: 95.20,
    priceType: 'steps',
    priceTiers: [{ to: 499, cost: 3.40 }, { to: 1999, cost: 3.15 }, { to: null, cost: 3.00 }],
    qtyMin: 28,
    title: 'EdShed Online Combined - Yearly',
    subtitle: 'From 28 Students.',
    test: true,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'edshed-metered-spelling-school-yearly-eur': {
    id: 'edshed-metered-spelling-school-yearly-eur',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: true,
    currency: 'eur',
    basePrice: 60.00,
    priceType: 'steps',
    priceTiers: [{ to: 36, cost: 1.66666 }, { to: 199, cost: 1.66 }, { to: 399, cost: 1.5 }, { to: 1999, cost: 1.40 }, { to: null, cost: 1.30 }],
    qtyMin: 36,
    title: 'Spelling Shed - Yearly',
    subtitle: 'From 36 Students.',
    test: true,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-eur'],
    maybeSwitchTo: []
  },
  'edshed-metered-number-school-yearly-eur': {
    id: 'edshed-metered-number-school-yearly-eur',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'eur',
    basePrice: 60.00,
    priceType: 'steps',
    priceTiers: [{ to: 36, cost: 1.66666 }, { to: 199, cost: 1.66 }, { to: 399, cost: 1.5 }, { to: 1999, cost: 1.40 }, { to: null, cost: 1.30 }],
    qtyMin: 36,
    title: 'MathShed - Yearly',
    subtitle: 'From 36 Students.',
    test: true,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-eur'],
    maybeSwitchTo: []
  },
  'edshed-metered-combined-school-yearly-eur': {
    id: 'edshed-metered-combined-school-yearly-eur',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'eur',
    basePrice: 100.00,
    priceType: 'steps',
    priceTiers: [{ to: 36, cost: 2.77776667 }, { to: 199, cost: 2.77 }, { to: 399, cost: 2.5 }, { to: 1999, cost: 2.34 }, { to: null, cost: 2.16 }],
    qtyMin: 36,
    title: 'EdShed Online Combined - Yearly',
    subtitle: 'From 36 Students.',
    test: true,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'edshed-metered-spelling-school-yearly-aud': {
    id: 'edshed-metered-spelling-school-yearly-aud',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: true,
    currency: 'aud',
    basePrice: 84.00,
    priceType: 'steps',
    priceTiers: [{ to: 499, cost: 3.00 }, { to: 1999, cost: 2.78 }, { to: null, cost: 2.63 }],
    qtyMin: 28,
    title: 'Spelling Shed - Yearly',
    subtitle: 'From 28 Students.',
    test: true,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-aud'],
    maybeSwitchTo: []
  },
  'edshed-metered-number-school-yearly-aud': {
    id: 'edshed-metered-number-school-yearly-aud',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'aud',
    basePrice: 84.00,
    priceType: 'steps',
    priceTiers: [{ to: 499, cost: 3.00 }, { to: 1999, cost: 2.78 }, { to: null, cost: 2.63 }],
    qtyMin: 28,
    title: 'MathShed - Yearly',
    subtitle: 'From 28 Students.',
    test: true,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-aud'],
    maybeSwitchTo: []
  },
  'edshed-metered-combined-school-yearly-aud': {
    id: 'edshed-metered-combined-school-yearly-aud',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'aud',
    basePrice: 140.00,
    priceType: 'steps',
    priceTiers: [{ to: 499, cost: 5.00 }, { to: 1999, cost: 4.63 }, { to: null, cost: 4.38 }],
    qtyMin: 28,
    title: 'EdShed Online Combined - Yearly',
    subtitle: 'From 28 Students.',
    test: true,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'edshed-metered-spelling-school-yearly-nzd': {
    id: 'edshed-metered-spelling-school-yearly-nzd',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: true,
    currency: 'nzd',
    basePrice: 84.00,
    priceType: 'steps',
    priceTiers: [{ to: 499, cost: 3.00 }, { to: 1999, cost: 2.78 }, { to: null, cost: 2.63 }],
    qtyMin: 28,
    title: 'Spelling Shed - Yearly',
    subtitle: 'From 28 Students.',
    test: true,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-nzd'],
    maybeSwitchTo: []
  },
  'edshed-metered-number-school-yearly-nzd': {
    id: 'edshed-metered-number-school-yearly-nzd',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'nzd',
    basePrice: 84.00,
    priceType: 'steps',
    priceTiers: [{ to: 499, cost: 3.00 }, { to: 1999, cost: 2.78 }, { to: null, cost: 2.63 }],
    qtyMin: 28,
    title: 'MathShed - Yearly',
    subtitle: 'From 28 Students.',
    test: true,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-nzd'],
    maybeSwitchTo: []
  },
  'edshed-metered-combined-school-yearly-nzd': {
    id: 'edshed-metered-combined-school-yearly-nzd',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'nzd',
    qtyMin: 28,
    basePrice: 140.00,
    priceType: 'steps',
    priceTiers: [{ to: 499, cost: 5.00 }, { to: 1999, cost: 4.63 }, { to: null, cost: 4.38 }],
    title: 'EdShed Online Combined - Yearly',
    subtitle: 'From 28 Students.',
    test: true,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  price_1Jp7OFEwnA0RkkU76DDwnsTl: {
    id: 'price_1Jp7OFEwnA0RkkU76DDwnsTl',
    accountRegion: 'US',
    group: 'edshed',
    period: 'year',

    spelling: false,
    number: false,
    phonics: false,
    phonics_lite_1: true,
    premium: true,
    school: true,
    currency: 'usd',
    teachers: 1,
    basePrice: 99.00,
    priceType: 'fixed',
    test: true,
    title: 'EdShed Online Phonics Intervention Pack 1 - Yearly',
    metered_entity: 'teacher',
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  price_1Jp7OZEwnA0RkkU7paqayfjG: {
    id: 'price_1Jp7OZEwnA0RkkU7paqayfjG',
    accountRegion: 'US',
    group: 'edshed',
    period: 'year',

    spelling: false,
    number: false,
    phonics: false,
    phonics_lite_2: true,
    premium: true,
    school: true,
    currency: 'usd',
    teachers: 1,
    basePrice: 99.00,
    priceType: 'fixed',
    test: true,
    title: 'EdShed Online Phonics Intervention Pack 2 - Yearly',
    metered_entity: 'teacher',
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  price_1Jp7OxEwnA0RkkU78bExnGfJ: {
    id: 'price_1Jp7OxEwnA0RkkU78bExnGfJ',
    accountRegion: 'US',
    group: 'edshed',
    period: 'year',

    spelling: false,
    number: false,
    phonics: false,
    phonics_lite_1: true,
    phonics_lite_2: true,
    premium: true,
    school: true,
    currency: 'usd',
    teachers: 1,
    basePrice: 149.00,
    priceType: 'fixed',
    test: true,
    title: 'EdShed Online Phonics Intervention Pack 1 + 2 - Yearly',
    metered_entity: 'teacher',
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'edshed-metered-phonics-yearly-gbp': {
    id: 'edshed-metered-phonics-yearly-gbp',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: false,
    phonics: true,
    premium: true,
    school: true,
    currency: 'gbp',
    pupils: 30,
    teachers: 1,
    classes: 1,
    basePrice: 50.00,
    priceType: 'steps',
    priceTiers: [{ to: null, cost: 50.00 }],
    title: 'EdShed Online Phonics - Yearly',
    metered_entity: 'class',
    test: true,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'edshed-metered-phonics-digital-yearly-gbp': {
    id: 'edshed-metered-phonics-digital-yearly-gbp',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: false,
    phonics: true,
    premium: true,
    school: true,
    currency: 'gbp',
    pupils: 36,
    teachers: 3,
    classes: 1,
    basePrice: 150.00,
    priceType: 'steps',
    priceTiers: [{ to: null, cost: 150.00 }],
    title: 'EdShed Online Phonics Digital - Yearly',
    metered_entity: 'class',
    test: true,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'edshed-metered-phonics-digital-yearly-eur': {
    id: 'edshed-metered-phonics-digital-yearly-eur',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: false,
    phonics: true,
    premium: true,
    school: true,
    currency: 'eur',
    pupils: 36,
    teachers: 3,
    classes: 1,
    basePrice: 200.00,
    priceType: 'steps',
    priceTiers: [{ to: null, cost: 200.00 }],
    title: 'EdShed Online Phonics Digital - Yearly',
    metered_entity: 'class',
    test: true,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'edshed-metered-phonics-digital-yearly-aud': {
    id: 'edshed-metered-phonics-digital-yearly-aud',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: false,
    phonics: true,
    premium: true,
    school: true,
    currency: 'aud',
    pupils: 36,
    teachers: 3,
    classes: 1,
    basePrice: 300.00,
    priceType: 'steps',
    priceTiers: [{ to: null, cost: 300.00 }],
    title: 'EdShed Online Phonics Digital - Yearly',
    metered_entity: 'class',
    test: true,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'edshed-metered-phonics-digital-yearly-nzd': {
    id: 'edshed-metered-phonics-digital-yearly-nzd',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: false,
    phonics: true,
    premium: true,
    school: true,
    currency: 'nzd',
    pupils: 36,
    teachers: 3,
    classes: 1,
    basePrice: 300.00,
    priceType: 'steps',
    priceTiers: [{ to: null, cost: 300.00 }],
    title: 'EdShed Online Phonics Digital - Yearly',
    metered_entity: 'class',
    test: true,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  price_1L3bfhEwnA0RkkU7oQZnBwmT: {
    id: 'price_1L3bfhEwnA0RkkU7oQZnBwmT',
    accountRegion: 'US',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: false,
    phonics: true,
    phonics_lite_1: true,
    phonics_lite_2: true,
    premium: true,
    school: true,
    currency: 'usd',
    pupils: 28,
    teachers: 2,
    classes: 1,
    basePrice: 249.00,
    priceType: 'steps',
    priceTiers: [{ to: null, cost: 249.00 }],
    title: 'EdShed Online Phonics Digital - Yearly - US',
    metered_entity: 'class',
    test: true,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  price_1Gu4aEEwnA0RkkU7twUAwyEG: {
    id: 'price_1Gu4aEEwnA0RkkU7twUAwyEG',
    accountRegion: 'US',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'usd',
    basePrice: 95.20,
    priceType: 'steps',
    priceTiers: [{ to: 499, cost: 3.40 }, { to: 1999, cost: 3.15 }, { to: null, cost: 3.00 }],
    qtyMin: 28,
    title: 'EdShed Online Combined - Yearly',
    subtitle: 'From 28 Students.',
    test: true,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  price_1Gu4YqEwnA0RkkU7sw73UzBx: {
    id: 'price_1Gu4YqEwnA0RkkU7sw73UzBx',
    accountRegion: 'US',
    group: 'edshed',
    period: 'year',

    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'usd',
    basePrice: 56.00,
    priceType: 'steps',
    priceTiers: [{ to: 499, cost: 2.00 }, { to: 1999, cost: 1.85 }, { to: null, cost: 1.75 }],
    qtyMin: 28,
    title: 'MathShed - Yearly',
    subtitle: 'From 28 Students.',
    test: true,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['price_1Gu4aEEwnA0RkkU7twUAwyEG'],
    maybeSwitchTo: []
  },
  price_1Gu4XAEwnA0RkkU7z4X2zKOv: {
    id: 'price_1Gu4XAEwnA0RkkU7z4X2zKOv',
    accountRegion: 'US',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: true,
    currency: 'usd',
    basePrice: 56.00,
    priceType: 'steps',
    priceTiers: [{ to: 499, cost: 2.00 }, { to: 1999, cost: 1.85 }, { to: null, cost: 1.75 }],
    qtyMin: 28,
    title: 'Spelling Shed - Yearly',
    subtitle: 'From 28 Students.',
    test: true,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['price_1Gu4agEwnA0RkkU7wVvr6g8W'],
    maybeSwitchTo: []
  },
  'edshed-metered-combined-school-yearly-zar': {
    id: 'edshed-metered-combined-school-yearly-zar',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: true,
    phonics: true,
    premium: true,
    school: true,
    currency: 'zar',
    basePrice: 1836.00,
    priceType: 'steps',
    priceTiers: [{ to: 36, cost: 51 }, { to: 199, cost: 51 }, { to: 399, cost: 45.90 }, { to: 1999, cost: 42.50 }, { to: null, cost: 39.10 }],
    qtyMin: 36,
    title: 'Spelling Shed, MathShed & Phonics Shed - Yearly',
    subtitle: 'From 36 Students.',
    test: true,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'edshed-metered-spelling-school-yearly-zar': {
    id: 'edshed-metered-spelling-school-yearly-zar',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: false,
    phonics: true,
    premium: true,
    school: true,
    currency: 'zar',
    basePrice: 1080.00,
    priceType: 'steps',
    priceTiers: [{ to: 36, cost: 30 }, { to: 199, cost: 30 }, { to: 399, cost: 27 }, { to: 1999, cost: 25 }, { to: null, cost: 23 }],
    qtyMin: 36,
    title: 'Spelling Shed and Phonics Shed - Yearly',
    subtitle: 'From 36 Students.',
    test: true,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-zar'],
    maybeSwitchTo: []
  },
  'edshed-metered-number-school-yearly-zar': {
    id: 'edshed-metered-number-school-yearly-zar',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'zar',
    basePrice: 1080.00,
    priceType: 'steps',
    priceTiers: [{ to: 36, cost: 30 }, { to: 199, cost: 30 }, { to: 399, cost: 27 }, { to: 1999, cost: 25 }, { to: null, cost: 23 }],
    qtyMin: 36,
    title: 'Spelling Shed - Yearly',
    subtitle: 'From 36 Students.',
    test: true,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-zar'],
    maybeSwitchTo: []
  },
  'edshed-metered-phonics-yearly-zar': {
    id: 'edshed-metered-phonics-yearly-zar',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: false,
    phonics: true,
    premium: true,
    school: true,
    currency: 'zar',
    pupils: 36,
    teachers: 3,
    classes: 1,
    basePrice: 3000.00,
    priceType: 'steps',
    priceTiers: [{ to: null, cost: 3000.00 }],
    title: 'EdShed Online Phonics Digital - Yearly',
    metered_entity: 'class',
    test: true,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'edshed-metered-spelling-home-yearly-zar': {
    id: 'edshed-metered-spelling-home-yearly-zar',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: true,
    number: false,
    phonics: true,
    premium: true,
    school: false,
    currency: 'zar',
    qtyMin: 5,
    basePrice: 500,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 100 }, { to: null, cost: 100 }],
    title: 'Spelling Shed & Phonics Shed - Home Yearly',
    subtitle: 'Up to 5 Children',
    test: true,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-yearly-zar'],
    maybeSwitchTo: []
  },
  'edshed-metered-number-home-yearly-zar': {
    id: 'edshed-metered-number-home-yearly-zar',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',

    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'zar',
    qtyMin: 5,
    basePrice: 500,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 100 }, { to: null, cost: 100 }],
    title: 'MathShed - Home Yearly',
    subtitle: 'Up to 5 Children',
    test: true,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-yearly-zar'],
    maybeSwitchTo: []
  },
  'edshed-metered-combined-home-yearly-zar': {
    id: 'edshed-metered-combined-home-yearly-zar',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    spelling: true,
    number: true,
    phonics: true,
    premium: true,
    school: false,
    currency: 'zar',
    qtyMin: 5,
    basePrice: 850,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 170 }, { to: null, cost: 170 }],
    title: 'Spelling Shed, MathShed & Phonics Shed - Yearly',
    subtitle: 'Up to 5 Children',
    test: true,
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: []
  },
  'edshed-metered-spelling-school-yearly-gbp-jan23': {
    id: 'edshed-metered-spelling-school-yearly-gbp-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    domestic: true,
    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: true,
    currency: 'gbp',
    basePrice: 66.50,
    priceType: 'steps',
    priceTiers: [{ to: 36, cost: 1.84722 }, { to: 199, cost: 2.00 }, { to: 399, cost: 1.8 }, { to: 1999, cost: 1.666667 }, { to: null, cost: 1.53333 }],
    qtyMin: 36,
    title: 'Spelling Shed - Yearly',
    subtitle: 'From 36 Pupils.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-gbp-jan23'],
    maybeSwitchTo: [],
    test: true
  },
  'edshed-metered-number-school-yearly-gbp-jan23': {
    id: 'edshed-metered-number-school-yearly-gbp-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    domestic: true,
    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'gbp',
    basePrice: 66.50,
    priceType: 'steps',
    priceTiers: [{ to: 36, cost: 1.84722 }, { to: 199, cost: 2.00 }, { to: 399, cost: 1.8 }, { to: 1999, cost: 1.666667 }, { to: null, cost: 1.53333 }],
    qtyMin: 36,
    title: 'MathShed - Yearly',
    subtitle: 'From 36 Pupils.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-gbp-jan23'],
    maybeSwitchTo: [],
    test: true
  },
  'edshed-metered-combined-school-yearly-gbp-jan23': {
    id: 'edshed-metered-combined-school-yearly-gbp-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    domestic: true,
    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'gbp',
    basePrice: 119.70,
    priceType: 'steps',
    priceTiers: [{ to: 36, cost: 3.325 }, { to: 199, cost: 3.60 }, { to: 399, cost: 3.24 }, { to: 1999, cost: 3.00 }, { to: null, cost: 2.76 }],
    qtyMin: 36,
    title: 'EdShed Online Combined - Yearly',
    subtitle: 'From 36 Pupils.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: [],
    test: true
  },
  'edshed-metered-spelling-school-yearly-eur-jan23': {
    id: 'edshed-metered-spelling-school-yearly-eur-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    domestic: false,
    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: true,
    currency: 'eur',
    basePrice: 170.00,
    priceType: 'steps',
    priceTiers: [{ to: 36, cost: 4.72222 }, { to: 199, cost: 4.75 }, { to: 399, cost: 4.29 }, { to: 1999, cost: 4.00 }, { to: null, cost: 3.72 }],
    qtyMin: 36,
    title: 'Spelling Shed - Yearly',
    subtitle: 'From 36 Students.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-eur-jan23'],
    maybeSwitchTo: [],
    test: true
  },
  'edshed-metered-number-school-yearly-eur-jan23': {
    id: 'edshed-metered-number-school-yearly-eur-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    domestic: false,
    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'eur',
    basePrice: 170.00,
    priceType: 'steps',
    priceTiers: [{ to: 36, cost: 4.72222 }, { to: 199, cost: 4.75 }, { to: 399, cost: 4.29 }, { to: 1999, cost: 4.00 }, { to: null, cost: 3.72 }],
    qtyMin: 36,
    title: 'MathShed - Yearly',
    subtitle: 'From 36 Students.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-eur-jan23'],
    maybeSwitchTo: [],
    test: true
  },
  'edshed-metered-combined-school-yearly-eur-jan23': {
    id: 'edshed-metered-combined-school-yearly-eur-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    domestic: false,
    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'eur',
    basePrice: 306,
    priceType: 'steps',
    priceTiers: [{ to: 36, cost: 8.5 }, { to: 199, cost: 8.55 }, { to: 399, cost: 7.72 }, { to: 1999, cost: 7.20 }, { to: null, cost: 6.70 }],
    qtyMin: 36,
    title: 'EdShed Online Combined - Yearly',
    subtitle: 'From 36 Students.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: [],
    test: true
  },
  'edshed-metered-spelling-school-yearly-aud-jan23': {
    id: 'edshed-metered-spelling-school-yearly-aud-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: true,
    currency: 'aud',
    basePrice: 210,
    priceType: 'steps',
    priceTiers: [{ to: 499, cost: 7.5 }, { to: 1999, cost: 6.95 }, { to: null, cost: 6.58 }],
    qtyMin: 28,
    title: 'Spelling Shed - Yearly',
    subtitle: 'From 28 Students.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-aud-jan23'],
    maybeSwitchTo: [],
    test: true
  },
  'edshed-metered-number-school-yearly-aud-jan23': {
    id: 'edshed-metered-number-school-yearly-aud-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'aud',
    basePrice: 210,
    priceType: 'steps',
    priceTiers: [{ to: 499, cost: 7.5 }, { to: 1999, cost: 6.95 }, { to: null, cost: 6.58 }],
    qtyMin: 28,
    title: 'MathShed - Yearly',
    subtitle: 'From 28 Students.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-aud-jan23'],
    maybeSwitchTo: [],
    test: true
  },
  'edshed-metered-combined-school-yearly-aud-jan23': {
    id: 'edshed-metered-combined-school-yearly-aud-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'aud',
    basePrice: 378,
    priceType: 'steps',
    priceTiers: [{ to: 499, cost: 13.5 }, { to: 1999, cost: 12.51 }, { to: null, cost: 11.84 }],
    qtyMin: 28,
    title: 'EdShed Online Combined - Yearly',
    subtitle: 'From 28 Students.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: [],
    test: true
  },
  'edshed-metered-spelling-school-yearly-nzd-jan23': {
    id: 'edshed-metered-spelling-school-yearly-nzd-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: true,
    currency: 'nzd',
    basePrice: 224,
    priceType: 'steps',
    priceTiers: [{ to: 499, cost: 8 }, { to: 1999, cost: 7.41 }, { to: null, cost: 7.00 }],
    qtyMin: 28,
    title: 'Spelling Shed - Yearly',
    subtitle: 'From 28 Students.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-nzd-jan23'],
    maybeSwitchTo: [],
    test: true
  },
  'edshed-metered-number-school-yearly-nzd-jan23': {
    id: 'edshed-metered-number-school-yearly-nzd-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'nzd',
    basePrice: 224,
    priceType: 'steps',
    priceTiers: [{ to: 499, cost: 8 }, { to: 1999, cost: 7.41 }, { to: null, cost: 7.00 }],
    qtyMin: 28,
    title: 'MathShed - Yearly',
    subtitle: 'From 28 Students.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-nzd-jan23'],
    maybeSwitchTo: [],
    test: true
  },
  'edshed-metered-combined-school-yearly-nzd-jan23': {
    id: 'edshed-metered-combined-school-yearly-nzd-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'nzd',
    qtyMin: 28,
    basePrice: 403.2,
    priceType: 'steps',
    priceTiers: [{ to: 499, cost: 14.40 }, { to: 1999, cost: 13.39 }, { to: null, cost: 12.6 }],
    title: 'EdShed Online Combined - Yearly',
    subtitle: 'From 28 Students.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: [],
    test: true
  },
  'edshed-metered-spelling-school-yearly-zar-jan23': {
    id: 'edshed-metered-spelling-school-yearly-zar-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    spelling: true,
    number: false,
    phonics: true,
    premium: true,
    school: true,
    currency: 'zar',
    basePrice: 3060.00,
    priceType: 'steps',
    priceTiers: [{ to: 36, cost: 85 }, { to: 199, cost: 85 }, { to: 399, cost: 77 }, { to: 1999, cost: 71 }, { to: null, cost: 65 }],
    qtyMin: 36,
    title: 'Spelling Shed and Phonics Shed - Yearly',
    subtitle: 'From 36 Students.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-zar-jan23'],
    maybeSwitchTo: [],
    test: true
  },
  'edshed-metered-number-school-yearly-zar-jan23': {
    id: 'edshed-metered-number-school-yearly-zar-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'zar',
    basePrice: 3060.00,
    priceType: 'steps',
    priceTiers: [{ to: 36, cost: 85 }, { to: 199, cost: 85 }, { to: 399, cost: 77 }, { to: 1999, cost: 71 }, { to: null, cost: 65 }],
    qtyMin: 36,
    title: 'Spelling Shed - Yearly',
    subtitle: 'From 36 Students.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-zar-jan23'],
    maybeSwitchTo: [],
    test: true
  },
  'edshed-metered-combined-school-yearly-zar-jan23': {
    id: 'edshed-metered-combined-school-yearly-zar-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    spelling: true,
    number: true,
    phonics: true,
    premium: true,
    school: true,
    currency: 'zar',
    basePrice: 5508.00,
    priceType: 'steps',
    priceTiers: [{ to: 36, cost: 153 }, { to: 199, cost: 153 }, { to: 399, cost: 139 }, { to: 1999, cost: 128 }, { to: null, cost: 117 }],
    qtyMin: 36,
    title: 'Spelling Shed, MathShed & Phonics Shed - Yearly',
    subtitle: 'From 36 Students.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: [],
    test: true
  },
  'litshed-personal-jan23': {
    id: 'litshed-personal-jan23',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',
    domestic: true,
    school: false,
    currency: 'gbp',
    teachers: 1,
    basePrice: 24.9917,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - Personal',
    canSwitchTo: [],
    maybeSwitchTo: [
      {
        condition: ['org_type', '=', 'school'],
        plan: 'litshed-metered-school-gbp-jan23'
      }
    ],
    test: true
  },
  'litshed-metered-school-gbp-jan23': {
    id: 'litshed-metered-school-gbp-jan23',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',
    domestic: true,
    school: true,
    currency: 'gbp',
    classes: 1,
    teachers: 4,
    qtyMin: 2,
    qtyMax: 40,
    basePrice: 47.50,
    priceType: 'steps',
    priceTiers: [{ to: null, cost: 23.75 }],
    title: 'Literacy Shed Plus - School',
    metered_entity: 'class',
    canSwitchTo: [],
    maybeSwitchTo: [],
    test: true
  },
  'litshed-personal-eur-jan23': {
    id: 'litshed-personal-eur-jan23',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',
    domestic: false,
    school: false,
    currency: 'eur',
    teachers: 1,
    basePrice: 33.99,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - Personal',
    canSwitchTo: [],
    maybeSwitchTo: [
      {
        condition: ['org_type', '=', 'school'],
        plan: 'litshed-metered-school-eur-jan23'
      }
    ],
    test: true
  },
  'litshed-metered-school-eur-jan23': {
    id: 'litshed-metered-school-eur-jan23',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',
    domestic: false,
    school: true,
    currency: 'eur',
    classes: 1,
    teachers: 4,
    qtyMin: 2,
    qtyMax: 40,
    basePrice: 65.50,
    priceType: 'steps',
    priceTiers: [{ to: null, cost: 32.75 }],
    title: 'Literacy Shed Plus - School',
    metered_entity: 'class',
    canSwitchTo: [],
    maybeSwitchTo: [],
    test: true
  },
  'litshed-personal-aud-jan23': {
    id: 'litshed-personal-aud-jan23',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',
    school: false,
    currency: 'aud',
    teachers: 1,
    basePrice: 54.99,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - Personal',
    canSwitchTo: [],
    maybeSwitchTo: [
      {
        condition: ['org_type', '=', 'school'],
        plan: 'litshed-metered-school-aud-jan23'
      }
    ],
    test: true
  },
  'litshed-metered-school-aud-jan23': {
    id: 'litshed-metered-school-aud-jan23',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',
    school: true,
    currency: 'aud',
    classes: 1,
    teachers: 4,
    qtyMin: 2,
    qtyMax: 40,
    basePrice: 102.00,
    priceType: 'steps',
    priceTiers: [{ to: null, cost: 51.00 }],
    title: 'Literacy Shed Plus - School',
    metered_entity: 'class',
    canSwitchTo: [],
    maybeSwitchTo: [],
    test: true
  },
  'litshed-personal-nzd-jan23': {
    id: 'litshed-personal-nzd-jan23',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',
    school: false,
    currency: 'nzd',
    teachers: 1,
    basePrice: 57.99,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - Personal',
    canSwitchTo: [],
    maybeSwitchTo: [
      {
        condition: ['org_type', '=', 'school'],
        plan: 'litshed-metered-school-nzd-jan23'
      }
    ],
    test: true
  },
  'litshed-metered-school-nzd-jan23': {
    id: 'litshed-metered-school-nzd-jan23',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',
    school: true,
    currency: 'nzd',
    classes: 1,
    teachers: 4,
    qtyMin: 2,
    qtyMax: 40,
    basePrice: 111.50,
    priceType: 'steps',
    priceTiers: [{ to: null, cost: 55.75 }],
    title: 'Literacy Shed Plus - School',
    metered_entity: 'class',
    canSwitchTo: [],
    maybeSwitchTo: [],
    test: true
  },
  'litshed-personal-zar-jan23': {
    id: 'litshed-personal-zar-jan23',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',
    school: false,
    currency: 'zar',
    teachers: 1,
    basePrice: 600,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - Personal',
    canSwitchTo: [],
    maybeSwitchTo: [
      {
        condition: ['org_type', '=', 'school'],
        plan: 'litshed-metered-school-zar-jan23'
      }
    ],
    test: true
  },
  'litshed-metered-school-zar-jan23': {
    id: 'litshed-metered-school-zar-jan23',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',
    school: true,
    currency: 'zar',
    classes: 1,
    teachers: 4,
    qtyMin: 2,
    qtyMax: 40,
    basePrice: 1140,
    priceType: 'steps',
    priceTiers: [{ to: null, cost: 570 }],
    title: 'Literacy Shed Plus - School',
    metered_entity: 'class',
    canSwitchTo: [],
    maybeSwitchTo: [],
    test: true
  },
  'edshed-metered-spelling-home-yearly-gbp-jan23': {
    id: 'edshed-metered-spelling-home-yearly-gbp-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    domestic: true,
    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: false,
    currency: 'gbp',
    qtyMin: 5,
    basePrice: 32.50,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 6.50 }, { to: null, cost: 6.50 }],
    title: 'Spelling Shed - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-yearly-gbp-jan23'],
    maybeSwitchTo: [],
    test: true
  },
  'edshed-metered-spelling-home-monthly-gbp-jan23': {
    id: 'edshed-metered-spelling-home-monthly-gbp-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'month',
    domestic: true,
    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: false,
    currency: 'gbp',
    qtyMin: 5,
    basePrice: 5.40,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 1.08 }, { to: null, cost: 1.08 }],
    title: 'Spelling Shed - Home Monthly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-monthly-gbp-jan23'],
    maybeSwitchTo: [
      {
        plan: 'edshed-metered-spelling-school-yearly-gbp-jan23',
        condition: ['org_type', '=', 'business']
      },
      {
        plan: 'edshed-metered-combined-school-yearly-gbp-jan23',
        condition: ['org_type', '=', 'business']
      }
    ],
    test: true
  },
  'edshed-metered-number-home-yearly-gbp-jan23': {
    id: 'edshed-metered-number-home-yearly-gbp-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    domestic: true,
    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'gbp',
    qtyMin: 5,
    basePrice: 32.50,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 6.50 }, { to: null, cost: 6.50 }],
    title: 'MathShed - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-yearly-gbp-jan23'],
    maybeSwitchTo: [],
    test: true
  },
  'edshed-metered-number-home-monthly-gbp-jan23': {
    id: 'edshed-metered-number-home-monthly-gbp-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'month',
    domestic: true,
    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'gbp',
    basePrice: 5.40,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 1.08 }, { to: null, cost: 1.08 }],
    qtyMin: 5,
    title: 'MathShed - Home Monthly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-monthly-gbp-jan23'],
    maybeSwitchTo: [
      {
        plan: 'edshed-metered-number-school-yearly-gbp-jan23',
        condition: ['org_type', '=', 'business']
      },
      {
        plan: 'edshed-metered-combined-school-yearly-gbp-jan23',
        condition: ['org_type', '=', 'business']
      }
    ],
    test: true
  },
  'edshed-metered-combined-home-yearly-gbp-jan23': {
    id: 'edshed-metered-combined-home-yearly-gbp-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    domestic: true,
    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'gbp',
    basePrice: 58.50,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 11.7 }, { to: null, cost: 11.7 }],
    qtyMin: 5,
    title: 'EdShed Online Combined - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: [],
    test: true
  },
  'edshed-metered-combined-home-monthly-gbp-jan23': {
    id: 'edshed-metered-combined-home-monthly-gbp-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'month',
    domestic: true,
    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'gbp',
    basePrice: 9.72,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 1.944 }, { to: null, cost: 1.94 }],
    qtyMin: 5,
    title: 'EdShed Online Combined - Home Monthly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: [
      {
        plan: 'edshed-metered-combined-school-yearly-gbp-jan23',
        condition: ['org_type', '=', 'business']
      }
    ],
    test: true
  },
  'edshed-metered-spelling-home-yearly-eur-jan23': {
    id: 'edshed-metered-spelling-home-yearly-eur-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    domestic: false,
    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: false,
    currency: 'eur',
    basePrice: 74.99,
    qtyMin: 5,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 14.998 }, { to: null, cost: 15 }],
    title: 'Spelling Shed - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-yearly-eur-jan23'],
    maybeSwitchTo: [],
    test: true
  },
  'edshed-metered-number-home-yearly-eur-jan23': {
    id: 'edshed-metered-number-home-yearly-eur-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    domestic: false,
    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'eur',
    basePrice: 74.99,
    qtyMin: 5,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 14.998 }, { to: null, cost: 15 }],
    title: 'MathShed - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-yearly-eur-jan23'],
    maybeSwitchTo: [],
    test: true
  },
  'edshed-metered-combined-home-yearly-eur-jan23': {
    id: 'edshed-metered-combined-home-yearly-eur-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    domestic: false,
    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'eur',
    basePrice: 134.98,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 26.996 }, { to: null, cost: 27 }],
    qtyMin: 5,
    title: 'EdShed Online Combined - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: [],
    test: true
  },
  'edshed-metered-spelling-home-yearly-aud-jan23': {
    id: 'edshed-metered-spelling-home-yearly-aud-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: false,
    currency: 'aud',
    basePrice: 74.99,
    qtyMin: 5,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 14.998 }, { to: null, cost: 15 }],
    title: 'Spelling Shed - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-yearly-aud-jan23'],
    maybeSwitchTo: [],
    test: true
  },
  'edshed-metered-number-home-yearly-aud-jan23': {
    id: 'edshed-metered-number-home-yearly-aud-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'aud',
    basePrice: 74.99,
    qtyMin: 5,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 14.998 }, { to: null, cost: 15 }],
    title: 'MathShed - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-yearly-aud-jan23'],
    maybeSwitchTo: [],
    test: true
  },
  'edshed-metered-combined-home-yearly-aud-jan23': {
    id: 'edshed-metered-combined-home-yearly-aud-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'aud',
    basePrice: 134.98,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 26.996 }, { to: null, cost: 27 }],
    qtyMin: 5,
    title: 'EdShed Online Combined - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: [],
    test: true
  },
  'edshed-metered-spelling-home-yearly-nzd-jan23': {
    id: 'edshed-metered-spelling-home-yearly-nzd-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: false,
    currency: 'nzd',
    basePrice: 74.99,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 14.998 }, { to: null, cost: 15 }],
    qtyMin: 5,
    title: 'Spelling Shed - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-yearly-nzd-jan23'],
    maybeSwitchTo: [],
    test: true
  },
  'edshed-metered-number-home-yearly-nzd-jan23': {
    id: 'edshed-metered-number-home-yearly-nzd-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'nzd',
    basePrice: 74.99,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 14.998 }, { to: null, cost: 15 }],
    qtyMin: 5,
    title: 'MathShed - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-yearly-nzd'],
    maybeSwitchTo: [],
    test: true
  },
  'edshed-metered-combined-home-yearly-nzd-jan23': {
    id: 'edshed-metered-combined-home-yearly-nzd-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'nzd',
    basePrice: 134.98,
    qtyMin: 5,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 26.996 }, { to: null, cost: 27 }],
    title: 'EdShed Online Combined - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: [],
    test: true
  },
  'edshed-metered-spelling-home-yearly-zar-jan23': {
    id: 'edshed-metered-spelling-home-yearly-zar-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    spelling: true,
    number: false,
    phonics: true,
    premium: true,
    school: false,
    currency: 'zar',
    qtyMin: 5,
    basePrice: 1250,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 250 }, { to: null, cost: 250 }],
    title: 'Spelling Shed & Phonics Shed - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-yearly-zar-jan23'],
    maybeSwitchTo: [],
    test: true
  },
  'edshed-metered-number-home-yearly-zar-jan23': {
    id: 'edshed-metered-number-home-yearly-zar-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'zar',
    qtyMin: 5,
    basePrice: 1250,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 250 }, { to: null, cost: 250 }],
    title: 'MathShed - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-yearly-zar-jan23'],
    maybeSwitchTo: [],
    test: true
  },
  'edshed-metered-combined-home-yearly-zar-jan23': {
    id: 'edshed-metered-combined-home-yearly-zar-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    spelling: true,
    number: true,
    phonics: true,
    premium: true,
    school: false,
    currency: 'zar',
    qtyMin: 5,
    basePrice: 2250,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 450 }, { to: null, cost: 450 }],
    title: 'Spelling Shed, MathShed & Phonics Shed - Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: [],
    test: true
  },
  'edshed-metered-spelling-school-yearly-gbp-intl-feb23': {
    id: 'edshed-metered-spelling-school-yearly-gbp-intl-feb23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    domestic: false,
    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: true,
    currency: 'gbp',
    basePrice: 150.00,
    priceType: 'steps',
    priceTiers: [{ to: 36, cost: 4.166667 }, { to: 199, cost: 4.23 }, { to: 399, cost: 3.82 }, { to: 1999, cost: 3.56 }, { to: null, cost: 3.31 }],
    qtyMin: 36,
    title: 'Spelling Shed - Yearly',
    subtitle: 'From 36 Pupils.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-gbp-intl-jan23'],
    maybeSwitchTo: [],
    test: true
  },
  'edshed-metered-number-school-yearly-gbp-intl-jan23': {
    id: 'edshed-metered-number-school-yearly-gbp-intl-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    domestic: false,
    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'gbp',
    basePrice: 150,
    priceType: 'steps',
    priceTiers: [{ to: 36, cost: 4.166667 }, { to: 199, cost: 4.23 }, { to: 399, cost: 3.82 }, { to: 1999, cost: 3.56 }, { to: null, cost: 3.31 }],
    qtyMin: 36,
    title: 'MathShed - Yearly',
    subtitle: 'From 36 Pupils.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-gbp-intl-jan23'],
    maybeSwitchTo: [],
    test: true
  },
  'edshed-metered-combined-school-yearly-gbp-intl-jan23': {
    id: 'edshed-metered-combined-school-yearly-gbp-intl-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    domestic: false,
    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'gbp',
    basePrice: 270,
    priceType: 'steps',
    priceTiers: [{ to: 36, cost: 7.50 }, { to: 199, cost: 7.61 }, { to: 399, cost: 6.88 }, { to: 1999, cost: 6.41 }, { to: null, cost: 5.96 }],
    qtyMin: 36,
    title: 'EdShed Online Combined - Yearly',
    subtitle: 'From 36 Pupils.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: [],
    test: true
  },
  'edshed-metered-spelling-school-yearly-eur-dom-jan23': {
    id: 'edshed-metered-spelling-school-yearly-eur-dom-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    domestic: true,
    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: true,
    currency: 'eur',
    basePrice: 75.00,
    priceType: 'steps',
    priceTiers: [{ to: 36, cost: 2.083333 }, { to: 199, cost: 2.26 }, { to: 399, cost: 2.03 }, { to: 1999, cost: 1.88 }, { to: null, cost: 1.73 }],
    qtyMin: 36,
    title: 'Spelling Shed - Yearly',
    subtitle: 'From 36 Students.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-eur-dom-jan23'],
    maybeSwitchTo: [],
    test: true
  },
  'edshed-metered-number-school-yearly-eur-dom-jan23': {
    id: 'edshed-metered-number-school-yearly-eur-dom-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    domestic: true,
    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'eur',
    basePrice: 75.00,
    priceType: 'steps',
    priceTiers: [{ to: 36, cost: 2.083333 }, { to: 199, cost: 2.26 }, { to: 399, cost: 2.03 }, { to: 1999, cost: 1.88 }, { to: null, cost: 1.73 }],
    qtyMin: 36,
    title: 'MathShed - Yearly',
    subtitle: 'From 36 Students.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-school-yearly-eur-dom-jan23'],
    maybeSwitchTo: [],
    test: true
  },
  'edshed-metered-combined-school-yearly-eur-dom-jan23': {
    id: 'edshed-metered-combined-school-yearly-eur-dom-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    domestic: true,
    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: true,
    currency: 'eur',
    basePrice: 135,
    priceType: 'steps',
    priceTiers: [{ to: 36, cost: 3.75 }, { to: 199, cost: 4.07 }, { to: 399, cost: 3.65 }, { to: 1999, cost: 3.38 }, { to: null, cost: 3.11 }],
    qtyMin: 36,
    title: 'EdShed Online Combined - Yearly',
    subtitle: 'From 36 Students.',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: [],
    test: true
  },
  'edshed-metered-spelling-home-yearly-gbp-intl-jan23': {
    id: 'edshed-metered-spelling-home-yearly-gbp-intl-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    domestic: false,
    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: false,
    currency: 'gbp',
    basePrice: 66.50,
    qtyMin: 5,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 13.30 }, { to: null, cost: 13.30 }],
    title: 'Spelling Shed - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-yearly-gbp-intl-jan23'],
    maybeSwitchTo: [],
    test: true
  },
  'edshed-metered-number-home-yearly-gbp-intl-jan23': {
    id: 'edshed-metered-number-home-yearly-gbp-intl-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    domestic: false,
    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'gbp',
    basePrice: 66.50,
    qtyMin: 5,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 13.30 }, { to: null, cost: 13.30 }],
    title: 'MathShed - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-yearly-gbp-intl-jan23'],
    maybeSwitchTo: [],
    test: true
  },
  'edshed-metered-combined-home-yearly-gbp-intl-jan23': {
    id: 'edshed-metered-combined-home-yearly-gbp-intl-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    domestic: false,
    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'gbp',
    basePrice: 119.70,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 23.94 }, { to: null, cost: 23.94 }],
    qtyMin: 5,
    title: 'EdShed Online Combined - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: [],
    test: true
  },
  'edshed-metered-spelling-home-yearly-eur-dom-jan23': {
    id: 'edshed-metered-spelling-home-yearly-eur-dom-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    domestic: true,
    spelling: true,
    number: false,
    phonics: false,
    premium: true,
    school: false,
    currency: 'eur',
    qtyMin: 5,
    basePrice: 36.50,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 7.30 }, { to: null, cost: 7.30 }],
    title: 'Spelling Shed - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-yearly-eur-dom-jan23'],
    maybeSwitchTo: [],
    test: true
  },
  'edshed-metered-number-home-yearly-eur-dom-jan23': {
    id: 'edshed-metered-number-home-yearly-eur-dom-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    domestic: true,
    spelling: false,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'eur',
    qtyMin: 5,
    basePrice: 36.50,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 7.30 }, { to: null, cost: 7.30 }],
    title: 'MathShed - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: ['edshed-metered-combined-home-yearly-eur-dom-jan23'],
    maybeSwitchTo: [],
    test: true
  },
  'edshed-metered-combined-home-yearly-eur-dom-jan23': {
    id: 'edshed-metered-combined-home-yearly-eur-dom-jan23',
    accountRegion: 'GB',
    group: 'edshed',
    period: 'year',
    domestic: true,
    spelling: true,
    number: true,
    phonics: false,
    premium: true,
    school: false,
    currency: 'eur',
    qtyMin: 5,
    basePrice: 65.70,
    priceType: 'tiers',
    priceTiers: [{ to: 5, cost: 13.14 }, { to: null, cost: 13.14 }],
    title: 'EdShed Online Combined - Home Yearly',
    subtitle: 'Up to 5 Children',
    metered_entity: 'pupil',
    pupils: 1,
    canSwitchTo: [],
    maybeSwitchTo: [],
    test: true
  },
  'litshed-personal-gbp-intl-jan23': {
    id: 'litshed-personal-gbp-intl-jan23',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',
    domestic: false,
    school: false,
    currency: 'gbp',
    teachers: 1,
    basePrice: 30.25,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - Personal',
    canSwitchTo: [],
    maybeSwitchTo: [
      {
        condition: ['org_type', '=', 'school'],
        plan: 'litshed-metered-school-gbp-intl-feb23'
      }
    ],
    test: true
  },
  'litshed-metered-school-gbp-intl-jan23': {
    id: 'litshed-metered-school-gbp-intl-jan23',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',
    domestic: false,
    school: true,
    currency: 'gbp',
    classes: 1,
    teachers: 4,
    qtyMin: 2,
    qtyMax: 40,
    basePrice: 58.50,
    priceType: 'steps',
    priceTiers: [{ to: null, cost: 29.25 }],
    title: 'Literacy Shed Plus - School',
    metered_entity: 'class',
    canSwitchTo: [],
    maybeSwitchTo: [],
    test: true
  },
  'litshed-metered-school-gbp-intl-feb23': {
    id: 'litshed-metered-school-gbp-intl-feb23',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',
    domestic: false,
    school: true,
    currency: 'gbp',
    classes: 1,
    teachers: 4,
    qtyMin: 2,
    qtyMax: 40,
    basePrice: 58.50,
    priceType: 'steps',
    priceTiers: [{ to: null, cost: 29.25 }],
    title: 'Literacy Shed Plus - School',
    metered_entity: 'class',
    canSwitchTo: [],
    maybeSwitchTo: [],
    test: true
  },
  'litshed-personal-eur-dom-jan23': {
    id: 'litshed-personal-eur-dom-jan23',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',
    domestic: true,
    school: false,
    currency: 'eur',
    teachers: 1,
    basePrice: 28.00,
    priceType: 'fixed',
    metered_entity: null,
    title: 'Literacy Shed Plus - Personal',
    canSwitchTo: [],
    maybeSwitchTo: [
      {
        condition: ['org_type', '=', 'school'],
        plan: 'litshed-metered-school-eur-dom-jan23'
      }
    ],
    test: true
  },
  'litshed-metered-school-eur-dom-jan23': {
    id: 'litshed-metered-school-eur-dom-jan23',
    accountRegion: 'GB',
    group: 'litshed',
    period: 'year',
    domestic: true,
    school: true,
    currency: 'eur',
    classes: 1,
    teachers: 4,
    qtyMin: 2,
    qtyMax: 40,
    basePrice: 53.50,
    priceType: 'steps',
    priceTiers: [{ to: null, cost: 26.75 }],
    title: 'Literacy Shed Plus - School',
    metered_entity: 'class',
    canSwitchTo: [],
    maybeSwitchTo: [],
    test: true
  }
}

export const devSubPlans: SubscriptionPlan[] = Object.values(subObj) as SubscriptionPlan[]
